import { platforms, countries } from "./constants";
export const makeAccountID = (account, withCountry = false) => {
  let _id, _name;

  let first_access =
    "manager" in account.access ? account.access.manager : "property" in account.access ? account.access.property : 1;
  let second_access =
    "account" in account.access ? account.access.account : "view" in account.access ? account.access.view : 1;

  let _first_access = first_access;
  let _second_access = second_access;

  if (first_access != 1) {
    _first_access = "_" + first_access;
  } else {
    _first_access = "";
  }

  if (second_access != 1) {
    _second_access = "_" + second_access;
  } else {
    _second_access = "";
  }

  _id = account.network + _first_access + _second_access;

  if (withCountry) {
    _id = account.country + "_" + account.network + _first_access + _second_access;
  }

  let f = first_access == 1 ? "" : " " + first_access;
  let s = second_access == 1 ? "" : " " + second_access;
  _name = platforms[account.network] + f + s;

  return {
    id: _id,
    name: _name,
  };
};

export const maekAccountIDFromLabel = (accountLabel) => {};

// Helper function to format the date
export const formatDate = (dateString) => {
    // Extract year, month, and day from the input string
    const year = dateString.slice(0, 4);
    const monthIndex = parseInt(dateString.slice(4, 6), 10) - 1; // JavaScript months are 0-based
    const day = parseInt(dateString.slice(6, 8), 10); // Remove leading zeros by converting to integer
  
    // Array of month names
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
    // Convert month index to month name
    const monthName = months[monthIndex];
  
    // Return formatted date string
    return `${day} ${monthName} ${year}`;
  };
  

export const formatEpochTimestamp = (epochTimestamp) => {
  // Create a Date object from the epoch timestamp (in milliseconds)
  const date = new Date(epochTimestamp * 1000); // epochTimestamp is usually in seconds

  // Array of month names
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // Extract day, month, and year
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  // Return the formatted date string
  return `${day} ${month} ${year}`;
};

export const subtractOneDay = (dateString) => {
    // Extract year, month, and day from the input string
    const year = parseInt(dateString.slice(0, 4), 10);
    const month = parseInt(dateString.slice(4, 6), 10) - 1; // JavaScript months are 0-based
    const day = parseInt(dateString.slice(6, 8), 10);
  
    // Create a Date object with the given year, month, and day
    const date = new Date(year, month, day);
  
    // Subtract one day
    date.setDate(date.getDate() + 1);
  
    // Extract the new year, month, and day
    const newYear = date.getFullYear();
    const newMonth = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, pad with leading zero
    const newDay = String(date.getDate()).padStart(2, '0'); // Pad with leading zero
  
    // Format the new date string in YYYYMMDD format
    return `${newYear}${newMonth}${newDay}`;
  };
  