import { materialRenderers, materialCells } from "@jsonforms/material-renderers";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { JsonForms } from "@jsonforms/react";
import { account_schema, general_config_schema } from "../data/general";

import { uischema_AccountsCreateOrUpdate, uischema_AccountsCreateOrUpdate_separate } from "../data/uischemas";
import "../static/css/Accounts.scss";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Paper,
  Snackbar,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { countries_dbs, countries_schema_dbs, platforms, useAccessForLabel } from "../data/constants";
import { data_pipeline_endpoint } from "../data/endpoints";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2f77ad",
    },
    secondary: {
      main: "#b53535",
    },
  },
  spacing: 4,
});

function AccountsCreateOrUpdate() {
  const [accountData, setAccountData] = useState({});
  const [accountSchema, setAccountSchema] = useState(account_schema);
  const [formErrors, setFormError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [backdropLoad, setBackdropLoad] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [currentPage, setCurrentPage] = useState("");
  const [snack, setSnack] = useState({
    open: false,
    msg: "",
    severity: "",
  });
  const [delDialog, setDelDialog] = useState(false);
  const { id, update } = useParams();

  let nav = useNavigate();
  const horizontal = "center";
  const vertical = "bottom";
  useEffect(() => {
    accountSchema.properties.account.properties.country.oneOf = countries_schema_dbs;
    if (!update && id) {
      // View
      uischema_AccountsCreateOrUpdate_separate.label = "View Account";
      setCurrentPage("view");
      setEditMode(false);
    } else if (!update && !id) {
      // New
      uischema_AccountsCreateOrUpdate_separate.label = "Add New Account";
      setCurrentPage("new");
      setEditMode(true);
    } else if (update && id) {
      // Update
      uischema_AccountsCreateOrUpdate_separate.label = "Update Account";
      setCurrentPage("update");
      setEditMode(true);
    }
  });

  useEffect(() => {
    var viewload = false;

    if (id) {
      setBackdropLoad(true);
      fetch(data_pipeline_endpoint + "?route=accounts/view/" + id)
        .then((r) => r.json())
        .then((d) => {
          setAccountData({ account: d });
        })
        .finally(() => {
          setBackdropLoad(false);
          viewload = true;
        });
    } else {
      setBackdropLoad(true);
      const fetchSchema = async () => {
        fetch("https://kasatria-public.s3.ap-southeast-1.amazonaws.com/schema/pipeline/account.schema.json")
          .then((r) => r.json())
          .then((d) => {
            setAccountSchema(d);
          })
          .finally(() => {
            setBackdropLoad(false);
          });
      };

      fetchSchema();
    }
  }, []);

  const handleSnackbarClose = (event, reason) => {
    //used for snackbar
    if (reason === "clickaway") {
      return;
    }

    setSnack((prevState) => ({ ...prevState, open: false }));
  };

  const do_addAccount = async (behavior = "default") => {
    setLoading(true);

    // const accountLabel =
    //   accountData.account.country +
    //   " " +
    //   platforms[accountData.account.network] +
    //   " " +
    //   accountData.account.access[
    //     useAccessForLabel[accountData.account.network]
    //   ];

    const payload = {
      route: "accounts/new",
      // label: accountLabel,
      ...accountData.account,
    };

    // console.log(payload);

    fetch(data_pipeline_endpoint, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((r) => r.json())
      .then((d) => {
        setSnack({
          msg: "Successfully saved account!",
          severity: "success",
          open: true,
        });

        setTimeout(() => {
          if (behavior == "default") {
            nav("/accounts/" + d.id, { replace: true });
            nav(0);
          } else if (behavior == "list") {
            nav("/accounts", { replace: true });
            nav(0);
          } else if (behavior == "another") {
            nav("/accounts/new", { replace: true });
            nav(0);
          }
        }, 1000);
      })
      .catch((e) => {
        setSnack({
          msg: "Couldn't save account!",
          severity: "error",
          open: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const do_updateAccount = async () => {
    // const accountLabel =
    //   accountData.account.country +
    //   " " +
    //   platforms[accountData.account.network] +
    //   " " +
    //   accountData.account.access[
    //     useAccessForLabel[accountData.account.network]
    //   ];

    const payload = {
      route: "accounts/update/" + id,
      // label: accountLabel,
      ...accountData.account,
    };

    fetch(data_pipeline_endpoint, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((r) => r.json())
      .then((d) => {
        setSnack({
          msg: "Successfully updated account!",
          severity: "success",
          open: true,
        });
      })
      .catch((e) => {
        setSnack({
          msg: "Couldn't update account!",
          severity: "error",
          open: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const do_editAccount = () => {
    nav(window.location.pathname + "/update", { replace: true });
  };

  const do_deleteAccount = () => {
    const payload = {
      route: "accounts/delete/" + id,
    };

    setLoadingDelete(true);

    fetch(data_pipeline_endpoint, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((r) => r.json())
      .then((d) => {
        setSnack({
          msg: "Successfully deleted account! Redirecting you to accounts list.",
          severity: "success",
          open: true,
        });

        setTimeout(() => {
          nav("/accounts");
        }, 2000);
      })
      .catch((e) => {
        console.warn(e);
        setSnack({
          msg: "Couldn't delete account!",
          severity: "error",
          open: true,
        });
      })
      .finally(() => {
        setDelDialog(false);
        setLoadingDelete(false);
      });
  };

  const confirmDel = (type) => {
    const open = () => {
      setDelDialog(true);
    };

    const close = () => {
      setDelDialog(false);
    };

    switch (type) {
      case "open":
        return open;

      case "close":
        return close;
    }
  };

  const goBack = () => {
    if (currentPage == "update") {
      nav("/accounts/" + id);
    } else {
      nav("/accounts");
    }
  };

  const initialData = {};
  if (currentPage != "new") {
    initialData.data = accountData;
  }

  let accessExists = false;
  if (typeof accountData !== "undefined") {
    if ("account" in accountData) {
      if ("access" in accountData.account) {
        accessExists = true;
      }
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md" className="accountsContainer">
        {/* <Box mb={2}>
          <Paper elevation={2} id="multiple-account">
            <h3>Upload Multiple Accounts</h3>
            <Divider />
          </Paper>
        </Box> */}

        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backdropLoad}>
          <CircularProgress color="inherit" size={72} />
        </Backdrop>

        <Box className="JSONForm-box">
          <JsonForms
            {...initialData}
            schema={accountSchema}
            uischema={uischema_AccountsCreateOrUpdate_separate}
            renderers={materialRenderers}
            cells={materialCells}
            onChange={({ data, errors }) => {
              setFormError(errors);
              setAccountData(data);
            }}
            readonly={!editMode}
            validationMode="ValidateAndShow"
          />
        </Box>

        <Box>
          <Button variant="contained" className="btn-back" onClick={goBack}>
            Back
          </Button>

          {/* Update */}
          {id && update && (
            <ButtonGroup variant="contained" className="float-r" disabled={formErrors.length > 0 || !accessExists}>
              <Button variant="contained" color="secondary" onClick={confirmDel("open")}>
                Delete Account
              </Button>
              <Button variant="contained" onClick={do_updateAccount}>
                {loading ? <CircularProgress size={24} color="inherit" /> : "Update Account"}
              </Button>
            </ButtonGroup>
          )}

          {/* View */}
          {id && !update && (
            <ButtonGroup variant="contained" className="float-r">
              <Button variant="contained" color="secondary" onClick={confirmDel("open")}>
                Delete Account
              </Button>
              <Button variant="contained" className={"float-r"} onClick={do_editAccount}>
                {loading ? <CircularProgress size={24} color="inherit" /> : "Edit Account"}
              </Button>
            </ButtonGroup>
          )}

          {/* New */}
          {!id && !update && (
            <ButtonGroup variant="contained" className="float-r" disabled={formErrors.length > 0 || !accessExists}>
              <Button variant="contained" className={"float-r"} onClick={() => do_addAccount("default")}>
                {loading ? <CircularProgress size={24} color="inherit" /> : "Save"}
              </Button>
              <Button variant="contained" className={"float-r"} onClick={() => do_addAccount("list")}>
                {loading ? <CircularProgress size={24} color="inherit" /> : "Save & Close"}
              </Button>
              <Button variant="contained" className={"float-r"} onClick={() => do_addAccount("another")}>
                {loading ? <CircularProgress size={24} color="inherit" /> : "Save & Add Another"}
              </Button>
            </ButtonGroup>
          )}
        </Box>

        <Snackbar
          open={snack.open}
          autoHideDuration={3500}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical, horizontal }}
        >
          <Alert onClose={handleSnackbarClose} severity={snack.severity} variant="filled">
            {snack.msg}
          </Alert>
        </Snackbar>
      </Container>

      <Dialog open={delDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Confirm account deletion?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Account deletion is permanent. <br></br>Are you sure you want to delete this account?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={confirmDel("close")} color="primary">
            Cancel
          </Button>
          <Button color="secondary" onClick={do_deleteAccount}>
            {loadingDelete ? <CircularProgress size={24} color="secondary" /> : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default AccountsCreateOrUpdate;
