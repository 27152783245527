import "../static/css/Accounts.scss";

import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Input,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ListItemLink } from "../utils";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { data_pipeline_endpoint } from "../data/endpoints";
import { getCurrentDDMMYYHHMM } from "../components/functions/helper";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2f77ad",
    },
    secondary: {
      main: "#b53535",
    },
  },
  spacing: 4,
});

function AccountsMultiManage() {
  // state declaration
  const [loading, setLoading] = useState(false);
  const [snack, setSnack] = useState({
    open: false,
    msg: "",
    severity: "",
  });
  const [accountList, setAccountList] = useState([]);
  const [accountFile, setAccountFile] = useState(null);
  const [backdropLoad, setBackdropLoad] = useState(false);
  const [accountLastUpdated, setAccountLastUpdated] = useState("[Unknown]");

  // variables declaration
  let nav = useNavigate();
  const horizontal = "center";
  const vertical = "bottom";

  // function declarations
  useEffect(() => {
    getLastSettingsMetadata();
  }, []);

  const handleSnackbarClose = (event, reason) => {
    //used for snackbar
    if (reason === "clickaway") {
      return;
    }

    setSnack((prevState) => ({ ...prevState, open: false }));
  };

  const goToAddNew = () => {
    nav("/accounts/new");
  };

  const downloadAccounts = () => {
    setBackdropLoad(true);
    const payload = {
      route: "accounts/download",
    };
    fetch(data_pipeline_endpoint, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((r) => r.blob())
      .then((d) => {
        const url = URL.createObjectURL(d);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `pipeline_accounts_${getCurrentDDMMYYHHMM()}.xlsx`;
        // Append the <a> element to the DOM and click it programmatically
        document.body.appendChild(a);
        a.click();

        // Clean up: remove the <a> element and revoke the object URL
        document.body.removeChild(a);
        URL.revokeObjectURL(url);

        setSnack({
          msg: "Successfully downloaded accounts!",
          severity: "success",
          open: true,
        });
      })
      .catch((e) => {
        console.log(e);
        setSnack({
          msg: "Failed to download accounts!",
          severity: "error",
          open: true,
        });
      })
      .finally(() => {
        setBackdropLoad(false);
      });
  };

  const getLastSettingsMetadata = () => {
    function parseDateString(dateString) {
      const date = new Date(dateString);

      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };

      const formattedDate = date.toLocaleString("en-GB", options); 

      return formattedDate; 
    }
    setBackdropLoad(true);
    const payload = {
      route: "accounts/metadata",
    };
    fetch(data_pipeline_endpoint, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((r) => r.json())
      .then((d) => {
        setAccountLastUpdated(parseDateString(d.updated));
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setBackdropLoad(false);
      });
  };

  const handleAccountUpload = (e) => {
    setBackdropLoad(true);
    const uploadAccounts = (f) => {
      const formData = new FormData();
      formData.append("file", f);

      const payload = {
        route: "accounts/upload",
      };

      formData.append("json", JSON.stringify(payload));

      fetch(data_pipeline_endpoint, {
        method: "POST",
        body: formData,
      })
        .then((r) => r.json())
        .then((d) => {
          setSnack({
            msg: "Successfully uploaded accounts!",
            severity: "success",
            open: true,
          });
        })
        .catch((e) => {
          console.error(e);
          setSnack({
            msg: "Failed to upload accounts!",
            severity: "error",
            open: true,
          });
        })
        .finally(() => {
          getLastSettingsMetadata();
          setBackdropLoad(false);
        });
    };
    const file = e.target.files[0];
    setAccountFile(file);
    uploadAccounts(file);
  };

  const goBack = () => {
    nav("/accounts");
  };
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md" className="accountsContainer">
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backdropLoad}>
          <CircularProgress color="inherit" size={72} />
        </Backdrop>

        <Box mb={2}>
          <Paper elevation={2} id="multiple-account">
            <h3>Manage Multiple Accounts</h3>

            <Divider />

            <Grid container mt={1} mb={4} spacing={4} className="multiAccount-grid" justifyContent="center">
              <Grid item xs={6} textAlign={"center"} className="griditem">
                <IconButton aria-label="download" className="iconButton" onClick={downloadAccounts}>
                  <DownloadForOfflineIcon sx={{ fontSize: "72px" }} />
                </IconButton>
                <Typography className="category_label">Download Existing Settings</Typography>
              </Grid>

              <Grid item xs={6} textAlign={"center"} className="griditem">
                <IconButton
                  aria-label="upload"
                  className="iconButton"
                  sx={{ transform: "rotate(180deg)" }}
                  component="label"
                >
                  <DownloadForOfflineIcon sx={{ fontSize: "72px" }} />

                  <Input type="file" sx={{ display: "none" }} onChange={handleAccountUpload} />
                </IconButton>
                <Typography className="category_label">Upload New Settings</Typography>
              </Grid>

              <Grid item xs={6} textAlign={"left"} className="griditem">
                <Typography color="primary" sx={{ fontSize: "0.7rem" }} textAlign={"center"}>
                  Created on {accountLastUpdated}
                </Typography>
              </Grid>

              <Grid item xs={6} textAlign={"left"} className="griditem">
                <Typography color="secondary" sx={{ fontSize: "0.7rem" }} textAlign={"center"}>
                  This will overwrite all accounts!
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Box>
        <Button variant="contained" className="btn-back" onClick={goBack}>
          Back
        </Button>

        <Snackbar
          open={snack.open}
          autoHideDuration={3500}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical, horizontal }}
        >
          <Alert onClose={handleSnackbarClose} severity={snack.severity} variant="filled">
            {snack.msg}
          </Alert>
        </Snackbar>
      </Container>
    </ThemeProvider>
  );
}

export default AccountsMultiManage;
