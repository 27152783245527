export const uischema_AccountsCreateOrUpdate = {
  type: "Group",
  label: "",
  elements: [
    {
      type: "Control",
      label: "Name",
      scope: "#/definitions/account/properties/name",
    },
    {
      type: "Control",
      label: "Country",
      scope: "#/definitions/account/properties/country",
    },
    {
      type: "Control",
      label: "Network",
      scope: "#/definitions/account/properties/network",
    },
    {
      type: "Group",
      label: "Access",
      elements: [
        {
          type: "Control",
          label: "Account",
          scope: "#/definitions/account/properties/access/properties/account",
          rule: {
            effect: "HIDE",
            condition: {
              scope: "#/definitions/account/properties/network",
              schema: {
                oneOf: [{ const: "ga" }, { const: "ga4" }],
              },
            },
          },
        },
        {
          type: "Control",
          label: "Manager",
          scope: "#/definitions/account/properties/access/properties/manager",
          rule: {
            effect: "HIDE",
            condition: {
              scope: "#/definitions/account/properties/network",
              schema: {
                oneOf: [{ const: "ga" }, { const: "ga4" }],
              },
            },
          },
        },
        {
          type: "Control",
          label: "View",
          scope: "#/definitions/account/properties/access/properties/view",
          rule: {
            effect: "SHOW",
            condition: {
              scope: "#/definitions/account/properties/network",
              schema: { const: "ga" },
            },
          },
        },
        {
          type: "Control",
          label: "Property",
          scope: "#/definitions/account/properties/access/properties/property",
          rule: {
            effect: "SHOW",
            condition: {
              scope: "#/definitions/account/properties/network",
              schema: {
                oneOf: [{ const: "ga" }, { const: "ga4" }],
              },
            },
          },
        },
      ],
    },
  ],
};

export const uischema_AccountsCreateOrUpdate_separate = {
  type: "Group",
  label: "",
  elements: [
    {
      type: "Control",
      label: "Name",
      scope: "#/properties/account/properties/name",
    },
    {
      type: "Control",
      label: "Country",
      scope: "#/properties/account/properties/country",
    },
    {
      type: "Control",
      label: "Network",
      scope: "#/properties/account/properties/network",
    },
    {
      type: "Group",
      label: "Access",
      elements: [
        {
          type: "Control",
          label: "Manager",
          scope: "#/properties/account/properties/access/properties/manager",
          rule: {
            effect: "HIDE",
            condition: {
              scope: "#/properties/account/properties/network",
              schema: {
                oneOf: [{ const: "ga" }, { const: "ga4" }],
              },
            },
          },
        },
        {
          type: "Control",
          label: "Account",
          scope: "#/properties/account/properties/access/properties/account",
          rule: {
            effect: "HIDE",
            condition: {
              scope: "#/properties/account/properties/network",
              schema: {
                oneOf: [{ const: "ga" }, { const: "ga4" }],
              },
            },
          },
        },
        {
          type: "Control",
          label: "View",
          scope: "#/properties/account/properties/access/properties/view",
          rule: {
            effect: "SHOW",
            condition: {
              scope: "#/properties/account/properties/network",
              schema: { const: "ga" },
            },
          },
        },
        {
          type: "Control",
          label: "Property",
          scope: "#/properties/account/properties/access/properties/property",
          rule: {
            effect: "SHOW",
            condition: {
              scope: "#/properties/account/properties/network",
              schema: {
                oneOf: [{ const: "ga" }, { const: "ga4" }],
              },
            },
          },
        },
      ],
    },
  ],
};
