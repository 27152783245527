import React, { useState, useEffect } from "react";
import "../static/css/Pipeline.scss";
import { theme } from "../data/general";
import { ThemeProvider } from "@emotion/react";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { data_pipeline_endpoint, job_historical_prod, job_realtime_prod } from "../data/endpoints";
import moment from "moment-timezone";
import _, { first, last } from "lodash";
import { platforms } from "../data/constants";
import cronParser from "cron-parser";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { formatDate, formatEpochTimestamp, makeAccountID, subtractOneDay } from "../data/helper";

function Pipeline() {
  const [timeframe, setTimeframe] = useState("today");
  const [snack, setSnack] = useState({
    open: false,
    msg: "",
    severity: "",
  });
  const [backdropLoad, setBackdropLoad] = useState(false);
  const [pipelineList, setPipelineList] = useState([]);
  const [jobList, setJobList] = useState({});

  // variables declaration
  let nav = useNavigate();
  const horizontal = "center";
  const vertical = "bottom";
  const loadStack = [];

  // const test_jobList = {
  //   "TW.D.ADSUMMARY": {
  //     20240725: {
  //       extract: {
  //         1: {
  //           status: "completed",
  //         },
  //         2: {
  //           status: "completed",
  //         },
  //       },
  //     },
  //   },
  //   "ID.D.ADSUMMARY": {
  //     20240726: {
  //       extract: {
  //         3: {
  //           status: "completed",
  //         },
  //         4: {
  //           status: "completed",
  //         },
  //       },
  //     },
  //   },
  // };

  useEffect(() => {
    populatePipelineList();
  }, []);

  const handleSnackbarClose = (event, reason) => {
    //used for snackbar
    if (reason === "clickaway") {
      return;
    }
    setSnack((prevState) => ({ ...prevState, open: false }));
  };

  const handleIconClick = (event) => {
    // Prevent event propagation to stop the Accordion from expanding/collapsing
    event.stopPropagation();
    // Handle any other logic you might want to perform
    console.log("Icon clicked!");
  };

  const handleEditClick = (event, code, pipelineIndex) => {
    // Prevent event propagation to stop the Accordion from expanding/collapsing
    event.stopPropagation();
    nav("/p/" + code, { state: { pipeline: pipelineList[pipelineIndex] } });
  };

  const goToNewPipeline = () => {
    nav("/p/new");
  };

  useEffect(() => {
    populateJobList();
  }, [pipelineList, timeframe]);

  const populatePipelineList = () => {
    startLoad();
    fetch(data_pipeline_endpoint + "?route=pipeline/list")
      .then((r) => r.json())
      .then((d) => {
        setPipelineList(d);
      })
      .catch((e) => {
        console.log(e);
        setSnack({
          msg: "Couldn't fetch pipelines!",
          severity: "error",
          open: true,
        });
      })
      .finally(() => {
        decideEndLoad();
      });
  };

  const startLoad = () => {
    setBackdropLoad(true);
    loadStack.push(true);
  };

  const decideEndLoad = () => {
    loadStack.pop();
    if (loadStack.length == 0) {
      setBackdropLoad(false);
    }
  };

  const generateDates = (period, getToday = false, getRealToday = false) => {
    const timeZone = "Asia/Singapore";

    const getFormattedDate = (date) => {
      return date.format("YYYYMMDD");
    };

    // Get today's date in the specified time zone
    const today = moment.tz(timeZone).startOf("day");
    const yesterday = today.clone().subtract(1, "day"); //use this as today as the pipeline runs for the day before.

    if (getToday) {
      if (getRealToday) {
        return getFormattedDate(today);
      } else {
        return getFormattedDate(yesterday);
      }
    }

    let startDate, endDate;

    switch (period) {
      case "today":
        startDate = yesterday.clone();
        endDate = yesterday.clone();
        break;

      case "week":
        startDate = yesterday.clone().startOf("week"); // Start of the week
        endDate = startDate.clone().endOf("week"); // End of the week
        break;

      case "month":
        startDate = yesterday.clone().startOf("month"); // Start of the month
        endDate = startDate.clone().endOf("month"); // End of the month
        break;

      case "quarter":
        startDate = yesterday.clone().startOf("quarter"); // Start of the quarter
        endDate = startDate.clone().endOf("quarter"); // End of the quarter
        break;

      case "year":
        startDate = yesterday.clone().startOf("year"); // Start of the year
        endDate = startDate.clone().endOf("year"); // End of the year
        break;

      default:
        throw new Error('Invalid period. Use "week", "month", "quarter", or "year".');
    }

    var dates = [];
    let currentDate = startDate.clone();
    const historical = [];
    const realtime = [];
    const future = [];

    while (currentDate.isSameOrBefore(endDate)) {
      if (currentDate.isBefore(yesterday, "day")) {
        historical.push(getFormattedDate(currentDate));
      } else if (currentDate.isSame(yesterday, "day")) {
        realtime.push(getFormattedDate(currentDate));
      } else if (currentDate.isAfter(yesterday, "day")) {
        future.push(getFormattedDate(currentDate));
      }

      dates.push(getFormattedDate(currentDate));
      currentDate.add(1, "day");
    }

    /**
     * returns dates = {
     * "historical": [],
     * "realtime": [],
     * "future": []
     * }
     */

    console.log({
      historical: historical,
      realtime: realtime,
      future: future,
    });
    return {
      historical: historical,
      realtime: realtime,
      future: future,
    };
  };

  const populateJobList = () => {
    startLoad();

    const dates = generateDates(timeframe);

    const codes = [];
    for (var p of pipelineList) {
      codes.push(p.code);
    }

    //realtime payload
    const all_dates = dates.realtime.concat(dates.historical);

    const payload = {
      pipeline: codes,
      dates: all_dates,
    };

    fetch(job_realtime_prod, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((r) => r.json())
      .then((realtime_data) => {
        setJobList(realtime_data);
      })
      .catch((e) => {
        console.log(e);
        setSnack({
          msg: "Failed to get latest job!",
          severity: "error",
          open: true,
        });
      })
      .finally(() => {
        decideEndLoad();
      });
  };

  const isEmpty = (obj) => {
    // Ensure we're dealing with an object
    if (typeof obj !== "object" || obj === null) {
      throw new TypeError("isEmpty function can only be called with objects");
    }

    return Object.keys(obj).length === 0;
  };

  const [jobElem, setJobElem] = useState({});
  const _jobElems = {};

  useEffect(() => {
    if (!isEmpty(jobList)) {
      console.log(jobList);
      for (var code in jobList) {
        createJob(code);
      }
      setJobTimes();
    }
  }, [jobList]);

  // const makeAccountID = (account) => {
  //   let _id, _name;

  //   let first_access =
  //     "manager" in account.access ? account.access.manager : "property" in account.access ? account.access.property : 1;
  //   let second_access =
  //     "account" in account.access ? account.access.account : "view" in account.access ? account.access.view : 1;

  //   let _first_access = first_access;
  //   let _second_access = second_access;

  //   if (first_access != 1) {
  //     _first_access = "_" + first_access;
  //   } else {
  //     _first_access = "";
  //   }

  //   if (second_access != 1) {
  //     _second_access = "_" + second_access;
  //   } else {
  //     _second_access = "";
  //   }

  //   _id = account.network + _first_access + _second_access;

  //   let f = first_access == 1 ? "" : " " + first_access;
  //   let s = second_access == 1 ? "" : " " + second_access;
  //   _name = platforms[account.network] + f + s;

  //   return {
  //     id: _id,
  //     name: _name,
  //   };
  // };

  const makeAccountName = (id) => {
    const split_id = id.split("_");

    if (split_id[1] != "") {
      split_id[1] = " " + split_id[1];
    }
    if (split_id[2]) {
      split_id[2] = " " + split_id[2];
    } else {
      split_id[2] = "";
    }

    return platforms[split_id[0]] + split_id[1] + split_id[2];
  };

  const createJob = (jobCode) => {
    const pipeline = pipelineList.find((item) => item.code === jobCode);

    const accountList = [];

    //this account list is the ones listed in general.config.json
    for (var account of pipeline.accounts) {
      accountList.push(makeAccountID(account));
    }

    const jobObj = jobList[jobCode];
    _jobElems[jobCode] = [];

    const dates = generateDates(timeframe);

    for (var date of dates.future) {
      jobObj[date] = {
        extract: {},
        consolidate: {},
        finalize: {},
        transport: {},
      };
    }

    console.log(jobObj);

    for (var j in jobObj) {
      var d = {};
      var jobStatus = "complete";
      var jobDetails = {};
      const overallCompleteStatus = {};
      var isFuture = false;

      if (dates.future.includes(j)) {
        isFuture = true;
      }

      var latest_ts = {};
      for (var stage of ["extract", "consolidate", "finalize", "transport"]) {
        overallCompleteStatus[stage] = <span className="jobstatus complete">Completed</span>;
        d[stage] = [];
        if (isEmpty(jobObj[j][stage])) {
          if (!isFuture) {
            jobStatus = "incomplete";
            overallCompleteStatus[stage] = <span className="jobstatus unknown">Unknown</span>;
            d[stage].push("No files found");
          } else {
            //if future job
            jobStatus = "scheduled";
            overallCompleteStatus[stage] = <span className="jobstatus unknown">Scheduled</span>;

            if (stage == "extract" || stage == "consolidate") {
              for (var [i, acc] of accountList.entries()) {
                if (i == 0) {
                  d[stage].push(
                    <Grid item xs={12}>
                      These accounts are scheduled to be processed:
                    </Grid>
                  );
                }

                d[stage].push(
                  <>
                    <Grid item xs={3}>
                      {acc.name}
                    </Grid>
                    <Grid item xs={1} textAlign={"center"} className="acc-jobstatus">
                      Scheduled
                    </Grid>
                    <Grid item xs={8} textAlign={"center"}></Grid>
                  </>
                );
              }
            } else {
              d[stage].push("No files found");
            }
          }
        }

        latest_ts[stage] = 0;
        for (const [acc, details] of Object.entries(jobObj[j][stage])) {
          if (details.end_ts > latest_ts[stage]) {
            latest_ts[stage] = details.end_ts;
          }
          
          if (details.status != "completed" && details.status != "processed") {
            jobStatus = "incomplete";

            if (j == generateDates("today", true)) {
              overallCompleteStatus[stage] = <span className="jobstatus processing">Pending / Processing</span>;
            } else {
              overallCompleteStatus[stage] = <span className="jobstatus failed">Error - Stuck</span>;
            }
          }
          
          if (details.status == "error") {
            jobStatus = "incomplete";
            overallCompleteStatus[stage] = <span className="jobstatus failed">Error - Unknown</span>;
          }

          if (stage == "extract" || stage == "consolidate") {
            d[stage].push(
              <>
                <Grid item xs={3}>
                  {makeAccountName(acc)}
                </Grid>
                <Grid item xs={1} textAlign={"center"} className="acc-jobstatus">
                  {details.status}
                </Grid>
                <Grid item xs={8} textAlign={"center"}></Grid>
              </>
            );
          } else {
            d[stage].push(
              <>
                <Grid item xs={3}>
                  {acc}
                </Grid>
                <Grid item xs={1} textAlign={"center"} className="acc-jobstatus">
                  {details.status}
                </Grid>
                <Grid item xs={8} textAlign={"center"}></Grid>
              </>
            );
          }
        }

        jobDetails[stage] = (
          <Grid container rowGap={3} className="jobdetail-subprocess">
            {d[stage]}
          </Grid>
        );



        if (latest_ts[stage] == 0 || jobStatus == 'incomplete') {
          latest_ts[stage] = "-";
        } else {
          latest_ts[stage] = formatEpochTimestamp(parseInt(latest_ts[stage]));
        }

      }

      let jobOverallStatusElem;

      if (jobStatus == "complete") {
        jobOverallStatusElem = (
          <Grid item xs={3} textAlign={"center"} className="jobstatus complete">
            Job Completed
          </Grid>
        );
      } else if (jobStatus == "incomplete") {
        jobOverallStatusElem = (
          <Grid item xs={3} textAlign={"center"} className="jobstatus failed">
            Job Incomplete
          </Grid>
        );
      } else if (jobStatus == "scheduled") {
        jobOverallStatusElem = (
          <Grid item xs={3} textAlign={"center"} className="jobstatus processing">
            Job Scheduled
          </Grid>
        );
      }

      const dateCreated = formatDate(subtractOneDay(j));
      _jobElems[jobCode].push(
        <Box mt={2}>
          <Accordion className="job" elevation={2}>
            <AccordionSummary className="jobsummary">
              <Grid container className="jobsummary-grid">
                <Grid item xs={4}>
                  Job [{j}]
                </Grid>
                {jobOverallStatusElem}
                <Grid item xs={3} textAlign={"center"}>
                  {/* <Typography color={"primary"} sx={{ fontSize: "0.75rem", textTransform: "uppercase" }}>
                    [Click to view details]
                  </Typography> */}
                </Grid>
                <Grid item xs={2} textAlign={"center"}></Grid>
              </Grid>
            </AccordionSummary>
            <Divider />
            <AccordionDetails className="jobdetail">
              <Grid container className="jobdetail-grid-header" mb={2}>
                <Grid item xs={3}>
                  Job Type
                </Grid>
                <Grid item xs={3} textAlign={"center"}>
                  Status
                </Grid>
                {/* <Grid item xs={4} textAlign={"center"}>
                  Schedule
                </Grid> */}
                <Grid item xs={3} textAlign={"center"}>
                  Date Scheduled
                </Grid>
                <Grid item xs={3} textAlign={"center"}>
                  Date Completed
                </Grid>
              </Grid>

              <Grid container className="jobdetail-grid">
                <Grid item xs={12}>
                  <Accordion elevation={0}>
                    <AccordionSummary>
                      <Grid container>
                        <Grid item xs={3}>
                          Extract
                        </Grid>
                        <Grid item xs={3} textAlign={"center"}>
                          {overallCompleteStatus["extract"]}
                        </Grid>
                        {/* <Grid item xs={4} textAlign={"center"}>
                          -
                        </Grid> */}
                        <Grid item xs={3} textAlign={"center"}>
                          {dateCreated}
                        </Grid>
                        <Grid item xs={3} textAlign={"center"}>
                          {latest_ts["extract"]}
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>{jobDetails["extract"]}</AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>

              <Grid container className="jobdetail-grid">
                <Grid item xs={12}>
                  <Accordion elevation={0}>
                    <AccordionSummary>
                      <Grid container>
                        <Grid item xs={3}>
                          Consolidate
                        </Grid>
                        <Grid item xs={3} textAlign={"center"}>
                          {overallCompleteStatus["consolidate"]}
                        </Grid>
                        {/* <Grid item xs={4} textAlign={"center"}>
                          -
                        </Grid> */}
                        <Grid item xs={3} textAlign={"center"}>
                          {dateCreated}
                        </Grid>
                        <Grid item xs={3} textAlign={"center"}>
                          {latest_ts["consolidate"]}
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>{jobDetails["consolidate"]}</AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>

              <Grid container className="jobdetail-grid">
                <Grid item xs={12}>
                  <Accordion elevation={0}>
                    <AccordionSummary>
                      <Grid container>
                        <Grid item xs={3}>
                          Finalize
                        </Grid>
                        <Grid item xs={3} textAlign={"center"}>
                          {overallCompleteStatus["finalize"]}
                        </Grid>
                        {/* <Grid item xs={4} textAlign={"center"}>
                          -
                        </Grid> */}
                        <Grid item xs={3} textAlign={"center"}>
                          {dateCreated}
                        </Grid>
                        <Grid item xs={3} textAlign={"center"}>
                          {latest_ts["finalize"]}
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>{jobDetails["finalize"]}</AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>

              <Grid container className="jobdetail-grid">
                <Grid item xs={12}>
                  <Accordion elevation={0}>
                    <AccordionSummary>
                      <Grid container>
                        <Grid item xs={3}>
                          Transport
                        </Grid>
                        <Grid item xs={3} textAlign={"center"}>
                          {overallCompleteStatus["transport"]}
                        </Grid>
                        {/* <Grid item xs={4} textAlign={"center"}>
                          -
                        </Grid> */}
                        <Grid item xs={3} textAlign={"center"}>
                          {dateCreated}
                        </Grid>
                        <Grid item xs={3} textAlign={"center"}>
                          {latest_ts["transport"]}
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>{jobDetails["transport"]}</AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
      );
    }

    setJobElem(_jobElems);
  };

  const [lastJobTime, setLastJobTime] = useState({});
  const [nextJobTime, setNextJobTime] = useState({});

  const setJobTimes = () => {
    dayjs.extend(utc);
    dayjs.extend(timezone);

    const _lastJobTime = {};
    const _nextJobTime = {};
    for (var code in jobList) {
      const todayDate = dayjs();
      const p = pipelineList.find((item) => item.code === code);
      const nextDate = getNextDate(todayDate, p.schedule, p.timezone);

      var lastJob, nextJob;
      if (todayDate.isSame(nextDate)) {
        lastJob = nextDate;
        nextJob = getNextDate(nextDate, p.schedule, p.timezone);
      } else {
        lastJob = getCurrentOccurrence(todayDate, p.schedule, p.timezone);
        nextJob = nextDate;
      }

      _lastJobTime[code] = lastJob.local().format("DD MMMM YYYY, hh:mm A");
      _nextJobTime[code] = nextJob.local().format("DD MMMM YYYY, hh:mm A");
    }

    console.log(_lastJobTime);

    setLastJobTime(_lastJobTime);
    setNextJobTime(_nextJobTime);

    function getNextDate(startDate, cronExpression, timezone) {
      // Create a cron parser instance
      const parser = cronParser.parseExpression(cronExpression, {
        currentDate: startDate.toDate(),
        tz: timezone, // Set the timezone for the cron expression
      });

      // Get the next occurrence
      const nextOccurrence = parser.next();

      // Convert to dayjs object
      return dayjs(nextOccurrence).tz(timezone);
    }

    function getCurrentOccurrence(startDate, cronExpression, timezone) {
      // Create a cron parser instance
      const parser = cronParser.parseExpression(cronExpression, {
        currentDate: startDate.toDate(),
        tz: timezone, // Set the timezone for the cron expression
      });

      // Get the current occurrence
      let currentOccurrence;
      try {
        currentOccurrence = parser.prev();
        if (parser.hasNext()) {
          // Check if the previous occurrence is before or at the start date
          if (dayjs(currentOccurrence).isAfter(startDate)) {
            // If it's after the start date, return null
            return null;
          }
        }
      } catch (e) {
        // If no previous occurrence is found, return null
        return null;
      }

      // Convert to dayjs object and return
      return dayjs(currentOccurrence).tz(timezone);
    }
  };

  return (
    <ThemeProvider theme={theme} key={"test"}>
      <Container maxWidth="xl" className="pipeline-home" sx={{ minWidth: "1024px" }}>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backdropLoad}>
          <CircularProgress color="inherit" size={72} />
        </Backdrop>

        {/* content */}
        <Box
          sx={{
            marginTop: "1rem",
            backgroundColor: "dimgray",
            padding: "1rem 1rem",
            alignContent: "center",
            justifyContent: "center",
            borderRadius: "6px",
          }}
        >
          <h3>Pipeline Dashboard</h3>
        </Box>

        <Box mb={2} mt={2}>
          <Paper elevation={2} sx={{ padding: "0.5rem 1.5rem" }}>
            <Grid container mt={5} mb={5} sx={{ justifyContent: "center", alignItems: "center" }}>
              <Grid item xs={8}>
                <FormControl sx={{ minWidth: "250px" }} size="small">
                  <InputLabel id="timeframe-select-label">Timeframe</InputLabel>
                  <Select
                    labelId="timeframe-select-label"
                    id="timeframe-select"
                    value={timeframe}
                    label="Timeframe"
                    onChange={(e) => {
                      setTimeframe(e.target.value);
                    }}
                  >
                    <MenuItem key="today" value={"today"}>
                      Today
                    </MenuItem>
                    <MenuItem key="week" value={"week"}>
                      This Week
                    </MenuItem>
                    <MenuItem key="month" value={"month"}>
                      This Month
                    </MenuItem>
                    <MenuItem key="quarter" value={"quarter"}>
                      This Quarter
                    </MenuItem>
                    <MenuItem key="year" value={"year"}>
                      This Year
                    </MenuItem>
                    <MenuItem key="alltime" value={"alltime"}>
                      All Time
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4} textAlign={"right"}>
                <Button variant="contained" onClick={goToNewPipeline}>
                  Add New Pipeline
                </Button>
              </Grid>
            </Grid>

            <Box mt={3} sx={{ backgroundColor: "rgba(0,0,0,0.05)", padding: "1.5rem 0", borderRadius: "4px" }}>
              <Grid container spacing={2} sx={{ padding: "0 2rem 1rem 2rem" }}>
                <Grid item xs={4}>
                  Pipeline
                </Grid>
                <Grid item xs={3} textAlign={"center"}>
                  Last Job
                </Grid>
                <Grid item xs={3} textAlign={"center"}>
                  Next Job
                </Grid>
                <Grid item xs={2} textAlign={"center"}>
                  Actions
                </Grid>
              </Grid>

              <Divider />

              {pipelineList.map((p, index) => (
                <Box mt={2} sx={{ padding: "0 12px" }} className="pipeline-accordion">
                  <Accordion elevation={1}>
                    <AccordionSummary sx={{ padding: "0 24px" }} className="pipelinesummary">
                      <Grid container spacing={2} sx={{ justifyContent: "center", alignItems: "center" }}>
                        <Grid item xs={4}>
                          {p.name}
                        </Grid>
                        <Grid item xs={3} textAlign={"center"}>
                          {lastJobTime[p.code] || "-"}
                        </Grid>
                        <Grid item xs={3} textAlign={"center"}>
                          {nextJobTime[p.code] || "-"}
                        </Grid>
                        <Grid item xs={2} textAlign={"center"}>
                          <EditIcon
                            className="editIcon tableIcon"
                            onClick={(e) => {
                              handleEditClick(e, p.code, index);
                            }}
                          />
                          <DeleteIcon className="delIcon tableIcon" onClick={handleIconClick} />
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <Divider />
                    <AccordionDetails>
                      {/* Job */}
                      {jobElem[p.code]}
                      {/* Job Ends */}
                    </AccordionDetails>
                  </Accordion>
                </Box>
              ))}
            </Box>
          </Paper>
        </Box>
        {/*  */}
        <Snackbar
          open={snack.open}
          autoHideDuration={3500}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical, horizontal }}
        >
          <Alert onClose={handleSnackbarClose} severity={snack.severity} variant="filled">
            {snack.msg}
          </Alert>
        </Snackbar>
      </Container>
    </ThemeProvider>
  );
}

export default Pipeline;
