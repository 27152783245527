import "../static/css/Accounts.scss";

import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Container,
  Divider,
  LinearProgress,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Snackbar,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ListItemLink } from "../utils";
import { data_pipeline_endpoint } from "../data/endpoints";

const theme = createTheme({
  // palette: {
  //   primary: {
  //     main: "#f5ccab",
  //   },
  //   secondary: {
  //     main: "#f5ccab",
  //   },
  // },
  // spacing: 4,
});

function AccountsList() {
  // state declaration
  const [loading, setLoading] = useState(false);
  const [snack, setSnack] = useState({
    open: false,
    msg: "",
    severity: "",
  });
  const [accountList, setAccountList] = useState([]);

  // variables declaration
  let nav = useNavigate();
  const horizontal = "center";
  const vertical = "bottom";

  // function declarations
  useEffect(() => {
    fetchAccountsList();
  }, []);

  const handleSnackbarClose = (event, reason) => {
    //used for snackbar
    if (reason === "clickaway") {
      return;
    }

    setSnack((prevState) => ({ ...prevState, open: false }));
  };

  const fetchAccountsList = () => {
    setLoading(true);
    fetch(
      data_pipeline_endpoint+"?route=accounts/list"
    )
      .then((r) => r.json())
      .then((d) => {
        console.log(d);
        setAccountList(d);
      })
      .catch((e) => {
        setSnack({
          open: true,
          msg: "Error fetching accounts.",
          severity: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const goToAddNew = () => {
    nav("/accounts/new");
  };

  const goToMultiManage = () => {
    nav("/accounts/multi")
  }
  const accounts = [];
  for (let account of accountList) {
    let accountLink = "/accounts/" + account.id;
    accounts.push(
      <ListItemLink primary={account.label} to={accountLink} key={account.id} />
    );
  }
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md" className="accountsContainer">
        <Box mb={2}>
          <Paper elevation={2} id="multiple-account">
            <h3>Accounts List</h3>

            <Divider />

            {loading && <LinearProgress />}
            <List>{accounts}</List>
          </Paper>
        </Box>

        <ButtonGroup
          variant="contained"
          aria-label="Basic button group"
          className="float-r"
        >
          <Button onClick={goToAddNew}>Add Account</Button>
          <Button onClick={goToMultiManage}>Manage Multiple Account</Button>
        </ButtonGroup>

        <Snackbar
          open={snack.open}
          autoHideDuration={3500}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical, horizontal }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snack.severity}
            variant="filled"
          >
            {snack.msg}
          </Alert>
        </Snackbar>
      </Container>
    </ThemeProvider>
  );
}

export default AccountsList;
