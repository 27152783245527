export function getCurrentDDMMYYHHMM() {
    // Get current date and time
    let now = new Date();

    // Extract individual components
    let day = String(now.getDate()).padStart(2, '0');     // Day (2 digits)
    let month = String(now.getMonth() + 1).padStart(2, '0'); // Month (0-11, hence +1 and 2 digits)
    let year = String(now.getFullYear()).slice(-2);      // Year (last 2 digits)
    let hours = String(now.getHours()).padStart(2, '0'); // Hours (24-hour format, 2 digits)
    let minutes = String(now.getMinutes()).padStart(2, '0'); // Minutes (2 digits)

    // Concatenate them in the desired format
    let formattedDateTime = day + month + year + hours + minutes;

    return formattedDateTime;
}
