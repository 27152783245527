import React, { useState, useEffect, useReducer } from "react";
import "../static/css/Pipeline.scss";
import { theme } from "../data/general";
import { ThemeProvider } from "@emotion/react";
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AddIcon from "@mui/icons-material/Add";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import $ from "jquery";

import { DatePicker } from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";
import PropTypes from "prop-types";
import AccountsList from "./AccountsList";
import { account_url, account_url_prod, data_pipeline_endpoint } from "../data/endpoints";
import dayjs from "dayjs";
import { makeAccountID } from "../data/helper";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="customTab"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function parseCronExpression(cronExpression) {
  // Split the cron expression into its components
  const parts = cronExpression.split(" ");

  if (parts.length !== 5) {
    throw new Error("Invalid cron expression. It must have exactly 5 fields.");
  }

  const [minute, hour, dayOfMonth, month, dayOfWeek] = parts;

  // Validate if the components are either numeric or '*'
  const isValidPart = (part) => part === "*" || /^[0-9]+$/.test(part);
  if (![minute, hour, dayOfMonth, month, dayOfWeek].every(isValidPart)) {
    throw new Error("Cron expression contains invalid values.");
  }

  // Format the time to "HH:MM"
  const formattedHour = hour.padStart(2, "0");
  const formattedMinute = minute.padStart(2, "0");
  const time = `${formattedHour}:${formattedMinute}`;

  // Determine the timeframe
  let timeframe;

  if (dayOfMonth === "*" && month === "*" && dayOfWeek === "*") {
    timeframe = "daily";
  } else if (dayOfMonth === "*" && month === "*" && dayOfWeek !== "*") {
    timeframe = "weekly";
  } else if (dayOfMonth !== "*" && month === "*" && dayOfWeek === "*") {
    timeframe = "monthly";
  } else if (dayOfMonth !== "*" && month !== "*" && dayOfWeek === "*") {
    timeframe = "yearly";
  } else {
    throw new Error("Unsupported cron expression format for timeframe determination.");
  }

  return { timeframe, time };
}

function frequencyToCron(frequency, time) {
  // Validate and parse the time input
  const timeParts = time.split(":");
  if (timeParts.length !== 2) {
    throw new Error("Time must be in 'HH:MM' format.");
  }

  const hour = parseInt(timeParts[0], 10);
  const minute = parseInt(timeParts[1], 10);

  if (isNaN(hour) || isNaN(minute) || hour < 0 || hour > 23 || minute < 0 || minute > 59) {
    throw new Error("Time must be in 'HH:MM' format with valid hour and minute values.");
  }

  let cronExpression;

  switch (frequency) {
    case "daily":
      cronExpression = `${minute} ${hour} * * *`;
      break;
    case "weekly":
      cronExpression = `${minute} ${hour} * * 0`; // Assuming Sunday as the start of the week
      break;
    case "monthly":
      cronExpression = `${minute} ${hour} 1 * *`; // The 1st day of every month
      break;
    case "yearly":
      cronExpression = `${minute} ${hour} 1 1 *`; // January 1st
      break;
    default:
      throw new Error("Invalid frequency. Must be one of 'daily', 'weekly', 'monthly', or 'yearly'.");
  }

  return cronExpression;
}

const timeStringToDayjs = (timeString) => {
  const [hours, minutes] = timeString.split(":").map(Number);
  return dayjs().hour(hours).minute(minutes).second(0);
};
function PipelineCreateOrUpdate() {
  const [input_accounts_selected, setAccountsInput] = useState([]);
  const [input_title, setTitleInput] = useState("");
  const [input_code, setCodeInput] = useState("");
  const [input_frequency, setFrequencyInput] = useState("daily");
  const [input_timezone, setTimezoneInput] = useState("Asia/Singapore");
  const [input_reports, setReportInput] = useState({
    ads: false,
    dv: false,
    ga: false,
    ga4: false,
    asa: false,
    fb: false,
    ln: false,
  });
  const [input_time, setTimeInput] = useState(dayjs());

  const [snack, setSnack] = useState({
    open: false,
    msg: "",
    severity: "",
  });
  const [backdropLoad, setBackdropLoad] = useState(false);
  const { pipeline_code } = useParams();
  const [tabValue, setTabValue] = useState(0);
  const [openAccounts, setOpenAccounts] = useState(false);
  const [accountsOption, setAccountsOptions] = useState([]);
  const [submitText, setSubmitText] = useState("SCHEDULE");

  // variables declaration
  let nav = useNavigate();
  const horizontal = "center";
  const vertical = "bottom";
  const loadStack = [];
  const timezones = Intl.supportedValuesOf("timeZone");

  useEffect(() => {
    if (pipeline_code) {
      $("#editTypeSpan").html("Edit");
      setSubmitText("SAVE");
      fetchPipeline();
    } else {
      $("#editTypeSpan").html("Add New");
    }
    fetchAccountList();

    console.log(accountsOption);
    console.log(input_accounts_selected);
  }, []);

  // useEffect(() => {}, [input_time]);

  const setExistingData = (pipeline) => {
    setTitleInput(pipeline.name);
    setCodeInput(pipeline.code);
    setTimezoneInput(pipeline.timezone);

    const _input_reports = input_reports;
    for (var platform of pipeline.collections) {
      if (platform.reports.includes(platform.network + "_summary")) {
        _input_reports[platform.network] = true;
      }
    }

    setReportInput(_input_reports);

    const cronData = parseCronExpression(pipeline.schedule);

    setFrequencyInput(cronData.timeframe);
    setTimeInput(timeStringToDayjs(cronData.time));

    const selectedAccounts = getSelectedAccounts(pipeline.accounts);
    setAccountsInput(selectedAccounts);
  };

  const getSelectedAccounts = (pipelineAccounts) => {
    const selected = [];
    for (var acc of pipelineAccounts) {
      selected.push(makeAccountID(acc).id);
    }

    return selected;
  };

  const startLoad = () => {
    setBackdropLoad(true);
    loadStack.push(true);
  };

  const decideEndLoad = () => {
    loadStack.pop();
    if (loadStack.length == 0) {
      setBackdropLoad(false);
    }
  };

  const fetchPipeline = () => {
    startLoad();

    const payload = {
      route: "pipeline/fetch",
      code: pipeline_code,
    };
    fetch(data_pipeline_endpoint, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((r) => r.json())
      .then((d) => {
        setExistingData(d);
      })
      .catch((e) => {
        console.log(e);
        setSnack({
          msg: "Failed to get pipeline!",
          severity: "error",
          open: true,
        });
      })
      .finally(() => {
        decideEndLoad();
      });
  };

  const fetchAccountList = () => {
    startLoad();

    fetch(data_pipeline_endpoint + "?route=accounts/list")
      .then((r) => r.json())
      .then((d) => {
        const accountsList = [];
        console.log(d);
        for (var account of d) {
          accountsList.push({
            value: makeAccountID(account).id,
            label: account.label,
          });
        }
        setAccountsOptions(accountsList);
      })
      .catch((e) => {
        setSnack({
          open: true,
          msg: "Error fetching accounts.",
          severity: "error",
        });
      })
      .finally(() => {
        decideEndLoad();
      });
  };

  const handleSnackbarClose = (event, reason) => {
    //used for snackbar
    if (reason === "clickaway") {
      return;
    }
    setSnack((prevState) => ({ ...prevState, open: false }));
  };

  const goToDashboard = () => {
    nav("/");
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleOpenAccounts = () => {
    setOpenAccounts(true);
  };

  const handleCloseAccounts = () => {
    fetchAccountList();
    setOpenAccounts(false);
  };

  const handleReportInput = (e, platform) => {
    const updatedReports = {
      ...input_reports,
      [platform]: e.target.checked,
    };

    setReportInput(updatedReports);
  };

  // const checkboxAttrib = (type) => {

  //   if(pipeline_code){
  //     return {
  //       checked: input_reports[type],
  //       onClick: handleReportInput(type),
  //     };
  //   }else{
  //     return {
  //       checked: input_reports[type],
  //       onClick: handleReportInput(type),
  //     };
  //   }

  // };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl" className="pipeline-home pipeline-createorupdate" sx={{ minWidth: "1024px" }}>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backdropLoad}>
          <CircularProgress color="inherit" size={72} />
        </Backdrop>

        {/* content */}
        <Box
          sx={{
            marginTop: "1rem",
            backgroundColor: "dimgray",
            padding: "1rem 1rem",
            alignContent: "center",
            justifyContent: "center",
            borderRadius: "6px",
          }}
        >
          <Grid container alignItems={"center"}>
            <Grid item xs={6}>
              <h3>
                <span id="editTypeSpan"></span> Pipeline
              </h3>
            </Grid>
            <Grid item xs={6} align={"right"}>
              <Button variant="contained" onClick={goToDashboard}>
                Back to dashboard
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box mb={2} mt={2}>
          <Paper elevation={2} sx={{ padding: "0.5rem 1.5rem 2rem 1.5rem" }}>
            {/* Pipeline */}
            <Box mt={3} sx={{ backgroundColor: "rgba(0,0,0,0.05)", padding: "1.5rem", borderRadius: "4px" }}>
              <Typography component={"span"} variant="h5" sx={{ fontWeight: "bold" }}>
                Pipeline
              </Typography>
              <Divider />
              <Grid container spacing={2} mt={2} sx={{ width: "50%" }} alignItems={"center"}>
                <Grid item xs={4}>
                  <Typography component={"span"} className="inputlabel">
                    Title
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    variant="outlined"
                    className="textinput1"
                    size="small"
                    value={input_title}
                    onInput={(e) => {
                      setTitleInput(e.target.value);
                    }}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Typography component={"span"} className="inputlabel">
                    Code
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    variant="outlined"
                    className="textinput1"
                    size="small"
                    value={input_code}
                    onInput={(e) => {
                      setCodeInput(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            {/* Frequency */}
            <Box mt={3} sx={{ backgroundColor: "rgba(0,0,0,0.05)", padding: "1.5rem", borderRadius: "4px" }}>
              <Typography component={"span"} variant="h5" sx={{ fontWeight: "bold" }}>
                Frequency
              </Typography>
              <Divider />
              <Grid container spacing={2} mt={2} sx={{ width: "50%" }} alignItems={"center"}>
                <Grid item xs={12} mb={4}>
                  <Typography component={"span"} className="inputlabel">
                    <span style={{ marginRight: "10px" }}>Create</span>
                    <Select
                      value={input_frequency}
                      onChange={(e) => {
                        setFrequencyInput(e.target.value);
                      }}
                      size="small"
                      sx={{ width: "150px" }}
                    >
                      <MenuItem value={"daily"}>Daily</MenuItem>
                      <MenuItem value={"weekly"}>Weekly</MenuItem>
                      <MenuItem value={"monthly"}>Monthly</MenuItem>
                      <MenuItem value={"yearly"}>Yearly</MenuItem>
                    </Select>
                    <span style={{ margin: "0 10px" }}>job.</span>
                    {/* <TextField type="number" size="small" sx={{ width: "75px" }} value="10"></TextField>
                    <span style={{ marginLeft: "10px" }} id="frequencyUnit">
                      days
                    </span> */}
                  </Typography>
                </Grid>

                {/* <Grid item xs={4}>
                  <Typography component={"span"} className="inputlabel">
                    Start date
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <DatePicker sx={{ width: "300px" }}></DatePicker>
                </Grid> */}

                <Grid item xs={4}>
                  <Typography component={"span"} className="inputlabel">
                    Start time
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TimePicker
                    sx={{ width: "300px" }}
                    value={input_time}
                    onChange={(newValue) => {
                      setTimeInput(newValue);
                    }}
                  ></TimePicker>
                </Grid>

                <Grid item xs={4}>
                  <Typography component={"span"} className="inputlabel">
                    Time Zone
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  {/* <Select
                    value={input_timezone}
                    onChange={(e) => {
                      setTimezoneInput(e.target.value);
                    }}
                    size="small"
                    sx={{ width: "300px" }}
                  >
                    <MenuItem value={"Asia/Singapore"}>Asia/Singapore (UTC +8:00)</MenuItem>
                  </Select> */}

                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={timezones}
                    value={input_timezone}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
              </Grid>
            </Box>

            {/* Accounts */}
            <Box mt={3} sx={{ backgroundColor: "rgba(0,0,0,0.05)", padding: "1.5rem", borderRadius: "4px" }}>
              <Typography component={"span"} variant="h5" sx={{ fontWeight: "bold" }}>
                Accounts
              </Typography>
              <Divider />
              <Grid container spacing={2} mt={2} sx={{ width: "50%" }} alignItems={"center"}>
                <Grid item xs={12}>
                  <DualListBox
                    canFilter
                    options={accountsOption}
                    selected={input_accounts_selected}
                    onChange={(newValue) => setAccountsInput(newValue)}
                    icons={{
                      moveToAvailable: <ChevronLeftIcon sx={{ fontSize: "1.5rem" }}></ChevronLeftIcon>,
                      moveToSelected: <ChevronRightIcon sx={{ fontSize: "1.5rem" }}></ChevronRightIcon>,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    onClick={handleOpenAccounts}
                    startIcon={<ManageAccountsIcon />}
                    size="small"
                  >
                    Manage accounts
                  </Button>
                </Grid>
              </Grid>
            </Box>

            {/* Reports */}
            <Box mt={3} sx={{ backgroundColor: "rgba(0,0,0,0.05)", padding: "1.5rem", borderRadius: "4px" }}>
              <Typography component={"span"} variant="h5" sx={{ fontWeight: "bold" }}>
                Reports
              </Typography>
              <Divider />

              <Box mt={4}>
                <Tabs className="customTabHeaders" value={tabValue} onChange={handleChangeTab}>
                  <Tab label="Google Ads" {...a11yProps(0)} />
                  <Tab label="DV360" {...a11yProps(1)} />
                  <Tab label="Google Analytics" {...a11yProps(2)} />
                  <Tab label="Apple Search Ads" {...a11yProps(3)} />
                  <Tab label="Facebook" {...a11yProps(4)} />
                  <Tab label="LinkedIn" {...a11yProps(5)} />
                </Tabs>
              </Box>

              <CustomTabPanel value={tabValue} index={0}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={input_reports["ads"]}
                      onClick={(e) => {
                        handleReportInput(e, "ads");
                      }}
                    />
                  }
                  label="Summary"
                />
              </CustomTabPanel>

              <CustomTabPanel value={tabValue} index={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={input_reports["dv"]}
                      onClick={(e) => {
                        handleReportInput(e, "dv");
                      }}
                    />
                  }
                  label="Summary"
                />
              </CustomTabPanel>

              <CustomTabPanel value={tabValue} index={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={input_reports["ga"]}
                      onClick={(e) => {
                        handleReportInput(e, "ga");
                      }}
                    />
                  }
                  label="Summary"
                />
              </CustomTabPanel>

              <CustomTabPanel value={tabValue} index={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={input_reports["asa"]}
                      onClick={(e) => {
                        handleReportInput(e, "asa");
                      }}
                    />
                  }
                  label="Summary"
                />
              </CustomTabPanel>

              <CustomTabPanel value={tabValue} index={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={input_reports["fb"]}
                      onClick={(e) => {
                        handleReportInput(e, "fb");
                      }}
                    />
                  }
                  label="Summary"
                />
              </CustomTabPanel>

              <CustomTabPanel value={tabValue} index={5}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={input_reports["ln"]}
                      onClick={(e) => {
                        handleReportInput(e, "ln");
                      }}
                    />
                  }
                  label="Summary"
                />
              </CustomTabPanel>
            </Box>

            <Dialog open={openAccounts} fullWidth maxWidth="md">
              {" "}
              <DialogActions sx={{ paddingRight: "24px" }}>
                <Button onClick={handleCloseAccounts} variant="contained" sx={{ minWidth: "200px" }}>
                  Close
                </Button>
              </DialogActions>
              <DialogContent sx={{ padding: "0px 24px 20px 24px" }}>
                <iframe
                  style={{ border: "none", borderRadius: "4px" }}
                  src={account_url_prod}
                  width="100%"
                  height="700px"
                ></iframe>
              </DialogContent>
            </Dialog>

            {/* Action Button */}
            <Box
              mt={3}
              sx={{
                padding: "1.5rem",
                borderRadius: "4px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
              className="actionBtns"
            >
              <Button variant="contained" id="resetBtn">
                Reset
              </Button>
              <Button variant="contained" id="schedBtn">
                {submitText}
              </Button>
            </Box>
          </Paper>
        </Box>

        {/*  */}
        <Snackbar
          open={snack.open}
          autoHideDuration={3500}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical, horizontal }}
        >
          <Alert onClose={handleSnackbarClose} severity={snack.severity} variant="filled">
            {snack.msg}
          </Alert>
        </Snackbar>
      </Container>
    </ThemeProvider>
  );
}

export default PipelineCreateOrUpdate;
