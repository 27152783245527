import { anyOf } from "@jsonforms/examples";
import { createTheme } from "@mui/material";

// export const general_config_schema = {
//   $schema: "http://json-schema.org/draft-07/schema#",
//   $id: "http://schema.kasatria.com/general.config.schema.json",
//   title: "General Config Pipeline Schema",
//   description: "A schema for a pipeline's general config.",
//   type: "object",
//   properties: {
//     pipelines: {
//       title: "Pipeline",
//       type: "array",
//       items: {
//         type: "object",
//         properties: {
//           name: {
//             type: "string",
//             description:
//               "The name/title of the pipeline. E.g: 'Daily ID Ad Summary'.",
//           },
//           code: {
//             type: "string",
//             description: "The code of the pipeline. E.g: 'ID.D.ADSUMMARY'.",
//           },
//           schedule: {
//             type: "string",
//             description:
//               "The schedule of the pipeline. Uses POSIX cron expression. E.g: '0 1 * * *'.",
//           },
//           timezone: {
//             type: "string",
//             description: "The timezone of the pipeline. E.g: 'Asia/Jakarta'.",
//           },
//           accounts: {
//             type: "array",
//             items: {
//               $ref: "#/definitions/account",
//             },
//             minItems: 1,
//           },
//           collections: {
//             type: "array",
//             items: {
//               $ref: "#/definitions/collection",
//             },
//             minItems: 1,
//           },
//           process: {
//             type: "object",
//             properties: {
//               extract: {
//                 type: "boolean",
//               },
//               consolidate: {
//                 type: "boolean",
//               },
//               finalize: {
//                 type: "boolean",
//               },
//               transport: {
//                 type: "boolean",
//               },
//             },
//             required: ["extract", "consolidate", "finalize", "transport"],
//           },
//         },
//         required: [
//           "name",
//           "code",
//           "schedule",
//           "timezone",
//           "accounts",
//           "collections",
//           "process",
//         ],
//       },
//     },
//     process: {
//       type: "object",
//       properties: {
//         max_line_per_file: {
//           type: "integer",
//         },
//       },
//     },
//   },
//   definitions: {
//     account: {
//       type: "object",
//       properties: {
//         id: {
//           type: "string",
//         },
//         country: {
//           oneOf: [
//             { title: "Afghanistan", const: "AF" },
//             { title: "Åland Islands", const: "AX" },
//             { title: "Albania", const: "AL" },
//             { title: "Algeria", const: "DZ" },
//             { title: "American Samoa", const: "AS" },
//             { title: "AndorrA", const: "AD" },
//             { title: "Angola", const: "AO" },
//             { title: "Anguilla", const: "AI" },
//             { title: "Antarctica", const: "AQ" },
//             { title: "Antigua and Barbuda", const: "AG" },
//             { title: "Argentina", const: "AR" },
//             { title: "Armenia", const: "AM" },
//             { title: "Aruba", const: "AW" },
//             { title: "Australia", const: "AU" },
//             { title: "Austria", const: "AT" },
//             { title: "Azerbaijan", const: "AZ" },
//             { title: "Bahamas", const: "BS" },
//             { title: "Bahrain", const: "BH" },
//             { title: "Bangladesh", const: "BD" },
//             { title: "Barbados", const: "BB" },
//             { title: "Belarus", const: "BY" },
//             { title: "Belgium", const: "BE" },
//             { title: "Belize", const: "BZ" },
//             { title: "Benin", const: "BJ" },
//             { title: "Bermuda", const: "BM" },
//             { title: "Bhutan", const: "BT" },
//             { title: "Bolivia", const: "BO" },
//             { title: "Bosnia and Herzegovina", const: "BA" },
//             { title: "Botswana", const: "BW" },
//             { title: "Bouvet Island", const: "BV" },
//             { title: "Brazil", const: "BR" },
//             { title: "British Indian Ocean Territory", const: "IO" },
//             { title: "Brunei Darussalam", const: "BN" },
//             { title: "Bulgaria", const: "BG" },
//             { title: "Burkina Faso", const: "BF" },
//             { title: "Burundi", const: "BI" },
//             { title: "Cambodia", const: "KH" },
//             { title: "Cameroon", const: "CM" },
//             { title: "Canada", const: "CA" },
//             { title: "Cape Verde", const: "CV" },
//             { title: "Cayman Islands", const: "KY" },
//             { title: "Central African Republic", const: "CF" },
//             { title: "Chad", const: "TD" },
//             { title: "Chile", const: "CL" },
//             { title: "China", const: "CN" },
//             { title: "Christmas Island", const: "CX" },
//             { title: "Cocos (Keeling) Islands", const: "CC" },
//             { title: "Colombia", const: "CO" },
//             { title: "Comoros", const: "KM" },
//             { title: "Congo", const: "CG" },
//             { title: "Congo, The Democratic Republic of the", const: "CD" },
//             { title: "Cook Islands", const: "CK" },
//             { title: "Costa Rica", const: "CR" },
//             { title: 'Cote D"Ivoire', const: "CI" },
//             { title: "Croatia", const: "HR" },
//             { title: "Cuba", const: "CU" },
//             { title: "Cyprus", const: "CY" },
//             { title: "Czech Republic", const: "CZ" },
//             { title: "Denmark", const: "DK" },
//             { title: "Djibouti", const: "DJ" },
//             { title: "Dominica", const: "DM" },
//             { title: "Dominican Republic", const: "DO" },
//             { title: "Ecuador", const: "EC" },
//             { title: "Egypt", const: "EG" },
//             { title: "El Salvador", const: "SV" },
//             { title: "Equatorial Guinea", const: "GQ" },
//             { title: "Eritrea", const: "ER" },
//             { title: "Estonia", const: "EE" },
//             { title: "Ethiopia", const: "ET" },
//             { title: "Falkland Islands (Malvinas)", const: "FK" },
//             { title: "Faroe Islands", const: "FO" },
//             { title: "Fiji", const: "FJ" },
//             { title: "Finland", const: "FI" },
//             { title: "France", const: "FR" },
//             { title: "French Guiana", const: "GF" },
//             { title: "French Polynesia", const: "PF" },
//             { title: "French Southern Territories", const: "TF" },
//             { title: "Gabon", const: "GA" },
//             { title: "Gambia", const: "GM" },
//             { title: "Georgia", const: "GE" },
//             { title: "Germany", const: "DE" },
//             { title: "Ghana", const: "GH" },
//             { title: "Gibraltar", const: "GI" },
//             { title: "Greece", const: "GR" },
//             { title: "Greenland", const: "GL" },
//             { title: "Grenada", const: "GD" },
//             { title: "Guadeloupe", const: "GP" },
//             { title: "Guam", const: "GU" },
//             { title: "Guatemala", const: "GT" },
//             { title: "Guernsey", const: "GG" },
//             { title: "Guinea", const: "GN" },
//             { title: "Guinea-Bissau", const: "GW" },
//             { title: "Guyana", const: "GY" },
//             { title: "Haiti", const: "HT" },
//             { title: "Heard Island and Mcdonald Islands", const: "HM" },
//             { title: "Holy See (Vatican City State)", const: "VA" },
//             { title: "Honduras", const: "HN" },
//             { title: "Hong Kong", const: "HK" },
//             { title: "Hungary", const: "HU" },
//             { title: "Iceland", const: "IS" },
//             { title: "India", const: "IN" },
//             { title: "Indonesia", const: "ID" },
//             { title: "Iran, Islamic Republic Of", const: "IR" },
//             { title: "Iraq", const: "IQ" },
//             { title: "Ireland", const: "IE" },
//             { title: "Isle of Man", const: "IM" },
//             { title: "Israel", const: "IL" },
//             { title: "Italy", const: "IT" },
//             { title: "Jamaica", const: "JM" },
//             { title: "Japan", const: "JP" },
//             { title: "Jersey", const: "JE" },
//             { title: "Jordan", const: "JO" },
//             { title: "Kazakhstan", const: "KZ" },
//             { title: "Kenya", const: "KE" },
//             { title: "Kiribati", const: "KI" },
//             { title: 'Korea, Democratic People"S Republic of', const: "KP" },
//             { title: "Korea, Republic of", const: "KR" },
//             { title: "Kuwait", const: "KW" },
//             { title: "Kyrgyzstan", const: "KG" },
//             { title: 'Lao People"S Democratic Republic', const: "LA" },
//             { title: "Latvia", const: "LV" },
//             { title: "Lebanon", const: "LB" },
//             { title: "Lesotho", const: "LS" },
//             { title: "Liberia", const: "LR" },
//             { title: "Libyan Arab Jamahiriya", const: "LY" },
//             { title: "Liechtenstein", const: "LI" },
//             { title: "Lithuania", const: "LT" },
//             { title: "Luxembourg", const: "LU" },
//             { title: "Macao", const: "MO" },
//             {
//               title: "Macedonia, The Former Yugoslav Republic of",
//               const: "MK",
//             },
//             { title: "Madagascar", const: "MG" },
//             { title: "Malawi", const: "MW" },
//             { title: "Malaysia", const: "MY" },
//             { title: "Maldives", const: "MV" },
//             { title: "Mali", const: "ML" },
//             { title: "Malta", const: "MT" },
//             { title: "Marshall Islands", const: "MH" },
//             { title: "Martinique", const: "MQ" },
//             { title: "Mauritania", const: "MR" },
//             { title: "Mauritius", const: "MU" },
//             { title: "Mayotte", const: "YT" },
//             { title: "Mexico", const: "MX" },
//             { title: "Micronesia, Federated States of", const: "FM" },
//             { title: "Moldova, Republic of", const: "MD" },
//             { title: "Monaco", const: "MC" },
//             { title: "Mongolia", const: "MN" },
//             { title: "Montserrat", const: "MS" },
//             { title: "Morocco", const: "MA" },
//             { title: "Mozambique", const: "MZ" },
//             { title: "Myanmar", const: "MM" },
//             { title: "Namibia", const: "NA" },
//             { title: "Nauru", const: "NR" },
//             { title: "Nepal", const: "NP" },
//             { title: "Netherlands", const: "NL" },
//             { title: "Netherlands Antilles", const: "AN" },
//             { title: "New Caledonia", const: "NC" },
//             { title: "New Zealand", const: "NZ" },
//             { title: "Nicaragua", const: "NI" },
//             { title: "Niger", const: "NE" },
//             { title: "Nigeria", const: "NG" },
//             { title: "Niue", const: "NU" },
//             { title: "Norfolk Island", const: "NF" },
//             { title: "Northern Mariana Islands", const: "MP" },
//             { title: "Norway", const: "NO" },
//             { title: "Oman", const: "OM" },
//             { title: "Pakistan", const: "PK" },
//             { title: "Palau", const: "PW" },
//             { title: "Palestinian Territory, Occupied", const: "PS" },
//             { title: "Panama", const: "PA" },
//             { title: "Papua New Guinea", const: "PG" },
//             { title: "Paraguay", const: "PY" },
//             { title: "Peru", const: "PE" },
//             { title: "Philippines", const: "PH" },
//             { title: "Pitcairn", const: "PN" },
//             { title: "Poland", const: "PL" },
//             { title: "Portugal", const: "PT" },
//             { title: "Puerto Rico", const: "PR" },
//             { title: "Qatar", const: "QA" },
//             { title: "Reunion", const: "RE" },
//             { title: "Romania", const: "RO" },
//             { title: "Russian Federation", const: "RU" },
//             { title: "RWANDA", const: "RW" },
//             { title: "Saint Helena", const: "SH" },
//             { title: "Saint Kitts and Nevis", const: "KN" },
//             { title: "Saint Lucia", const: "LC" },
//             { title: "Saint Pierre and Miquelon", const: "PM" },
//             { title: "Saint Vincent and the Grenadines", const: "VC" },
//             { title: "Samoa", const: "WS" },
//             { title: "San Marino", const: "SM" },
//             { title: "Sao Tome and Principe", const: "ST" },
//             { title: "Saudi Arabia", const: "SA" },
//             { title: "Senegal", const: "SN" },
//             { title: "Serbia and Montenegro", const: "CS" },
//             { title: "Seychelles", const: "SC" },
//             { title: "Sierra Leone", const: "SL" },
//             { title: "Singapore", const: "SG" },
//             { title: "Slovakia", const: "SK" },
//             { title: "Slovenia", const: "SI" },
//             { title: "Solomon Islands", const: "SB" },
//             { title: "Somalia", const: "SO" },
//             { title: "South Africa", const: "ZA" },
//             {
//               title: "South Georgia and the South Sandwich Islands",
//               const: "GS",
//             },
//             { title: "Spain", const: "ES" },
//             { title: "Sri Lanka", const: "LK" },
//             { title: "Sudan", const: "SD" },
//             { title: "Suriname", const: "SR" },
//             { title: "Svalbard and Jan Mayen", const: "SJ" },
//             { title: "Swaziland", const: "SZ" },
//             { title: "Sweden", const: "SE" },
//             { title: "Switzerland", const: "CH" },
//             { title: "Syrian Arab Republic", const: "SY" },
//             { title: "Taiwan", const: "TW" },
//             { title: "Tajikistan", const: "TJ" },
//             { title: "Tanzania, United Republic of", const: "TZ" },
//             { title: "Thailand", const: "TH" },
//             { title: "Timor-Leste", const: "TL" },
//             { title: "Togo", const: "TG" },
//             { title: "Tokelau", const: "TK" },
//             { title: "Tonga", const: "TO" },
//             { title: "Trinidad and Tobago", const: "TT" },
//             { title: "Tunisia", const: "TN" },
//             { title: "Turkey", const: "TR" },
//             { title: "Turkmenistan", const: "TM" },
//             { title: "Turks and Caicos Islands", const: "TC" },
//             { title: "Tuvalu", const: "TV" },
//             { title: "Uganda", const: "UG" },
//             { title: "Ukraine", const: "UA" },
//             { title: "United Arab Emirates", const: "AE" },
//             { title: "United Kingdom", const: "GB" },
//             { title: "United States", const: "US" },
//             { title: "United States Minor Outlying Islands", const: "UM" },
//             { title: "Uruguay", const: "UY" },
//             { title: "Uzbekistan", const: "UZ" },
//             { title: "Vanuatu", const: "VU" },
//             { title: "Venezuela", const: "VE" },
//             { title: "Viet Nam", const: "VN" },
//             { title: "Virgin Islands, British", const: "VG" },
//             { title: "Virgin Islands, U.S.", const: "VI" },
//             { title: "Wallis and Futuna", const: "WF" },
//             { title: "Western Sahara", const: "EH" },
//             { title: "Yemen", const: "YE" },
//             { title: "Zambia", const: "ZM" },
//             { title: "Zimbabwe", const: "ZW" },
//           ],
//         },
//         network: {
//           oneOf: [
//             { const: "ads", title: "Google Ads" },
//             { const: "dv", title: "Google DV360" },
//             { const: "ga", title: "Google Analytics" },
//             { const: "ga4", title: "Google Analytics 4" },
//             { const: "fb", title: "Facebook" },
//             { const: "asa", title: "Apple Search Ads" },
//             { const: "ln", title: "LinkedIns" },
//             { const: "bing", title: "Bing" },
//             { const: "email", title: "Email" },
//           ],
//         },
//         label: {
//           type: "string",
//         },
//         name: {
//           type: "string",
//         },
//       },
//       if: {
//         properties: {
//           network: { const: "ga" },
//         },
//       },
//       then: {
//         properties: {
//           access: {
//             type: "object",
//             properties: {
//               property: {
//                 type: "string",
//               },
//               view: {
//                 type: "string",
//               },
//             },
//             required: ["property", "view"],
//           },
//         },
//       },
//       else: {
//         if: {
//           properties: {
//             network: { const: "ga4" },
//           },
//         },
//         then: {
//           properties: {
//             access: {
//               type: "object",
//               properties: {
//                 property: {
//                   type: "string",
//                 },
//               },
//               required: ["property"],
//             },
//           },
//         },
//         else: {
//           properties: {
//             access: {
//               type: "object",
//               properties: {
//                 account: {
//                   type: "string",
//                 },
//                 manager: {
//                   type: "string",
//                 },
//               },
//               required: ["account"],
//             },
//           },
//         },
//       },
//     },
//     collection: {
//       type: "object",
//       properties: {
//         network: {
//           enum: ["ads", "dv", "ga", "fb", "asa", "ln", "bing", "email"],
//         },
//         reports: {
//           type: "array",
//           items: {
//             type: "string",
//             description: "ID of report",
//           },
//           minItems: 1,
//         },
//       },
//     },
//   },
// };

// export const general_config_data = {
//   pipelines: [
//     {
//       name: "Daily ID Ad Summary",
//       code: "ID.D.ADSUMMARY",
//       schedule: "0 1 * * *",
//       timezone: "Asia/Jakarta",
//       accounts: [
//         {
//           country: "ID",
//           network: "ads",
//           label: "Digibank",
//           access: {
//             account: "6487241252",
//             manager: "2557464993",
//           },
//         },
//         {
//           country: "ID",
//           network: "ads",
//           label: "DBS CC",
//           access: {
//             account: "9771786672",
//             manager: "2557464993",
//           },
//         },
//         {
//           country: "ID",
//           network: "ads",
//           label: "DBS SW Ace",
//           access: {
//             account: "7633711013",
//             manager: "2557464993",
//           },
//         },
//         {
//           country: "ID",
//           network: "ads",
//           label: "Digibank KTA",
//           access: {
//             account: "4930118878",
//             manager: "2557464993",
//           },
//         },
//         {
//           country: "ID",
//           network: "ads",
//           label: "DBS iWealth",
//           access: {
//             account: "2055124101",
//             manager: "2557464993",
//           },
//         },
//         {
//           country: "ID",
//           network: "ads",
//           label: "Olround Digibank",
//           access: {
//             account: "2076712415",
//             manager: "2557464993",
//           },
//         },
//       ],
//       collections: [
//         {
//           network: "ads",
//           reports: ["ads_summary"],
//         },
//         {
//           network: "dv",
//           reports: ["dv_summary"],
//         },
//         {
//           network: "ga",
//           reports: ["ga_summary"],
//         },
//         {
//           network: "fb",
//           reports: ["fb_summary"],
//         },
//         {
//           network: "asa",
//           reports: ["asa_summary"],
//         },
//         {
//           network: "ln",
//           reports: ["ln_summary"],
//         },
//       ],
//       process: {
//         extract: true,
//         consolidate: true,
//         finalize: true,
//         transport: true,
//       },
//     },
//     {
//       name: "Daily TW Ad Summary",
//       code: "TW.D.ADSUMMARY",
//       schedule: "0 1 * * *",
//       timezone: "Asia/Taipei",
//       accounts: [
//         {
//           country: "TW",
//           network: "ads",
//           access: {
//             account: "8891263872",
//             manager: "4730013168",
//           },
//         },
//         {
//           country: "TW",
//           network: "ads",
//           access: {
//             account: "6091220580",
//             manager: "4730013168",
//           },
//         },
//         {
//           country: "TW",
//           network: "ads",
//           access: {
//             account: "1973708676",
//             manager: "4730013168",
//           },
//         },
//         {
//           country: "TW",
//           network: "ads",
//           access: {
//             account: "2172927107",
//             manager: "4730013168",
//           },
//         },
//         {
//           country: "TW",
//           network: "ads",
//           access: {
//             account: "1146400146",
//             manager: "4730013168",
//           },
//         },
//         {
//           country: "TW",
//           network: "ads",
//           access: {
//             account: "8113863729",
//             manager: "4730013168",
//           },
//         },
//         {
//           country: "TW",
//           network: "ads",
//           access: {
//             account: "3887316382",
//             manager: "4730013168",
//           },
//         },
//         {
//           country: "TW",
//           network: "ads",
//           access: {
//             account: "3302910364",
//             manager: "4730013168",
//           },
//         },
//         {
//           country: "TW",
//           network: "ads",
//           access: {
//             account: "2153642096",
//             manager: "4730013168",
//           },
//         },
//         {
//           country: "TW",
//           network: "dv",
//           access: {
//             account: "520110179",
//           },
//         },
//         {
//           country: "TW",
//           network: "ga",
//           access: {
//             property: "UA-49528693-1",
//             view: "84169444",
//           },
//         },
//       ],
//       collections: [
//         {
//           network: "ads",
//           reports: ["ads_summary"],
//         },
//         {
//           network: "dv",
//           reports: ["dv_summary"],
//         },
//         {
//           network: "ga",
//           reports: ["ga_summary"],
//         },
//         {
//           network: "fb",
//           reports: ["fb_summary"],
//         },
//         {
//           network: "asa",
//           reports: ["asa_summary"],
//         },
//         {
//           network: "ln",
//           reports: ["ln_summary"],
//         },
//       ],
//       process: {
//         extract: true,
//         consolidate: true,
//         finalize: true,
//         transport: true,
//       },
//     },
//     {
//       name: "Daily IN Ad Summary",
//       code: "IN.D.ADSUMMARY",
//       schedule: "0 1 * * *",
//       timezone: "Asia/Calcutta",
//       accounts: [
//         {
//           country: "IN",
//           network: "ads",
//           access: {
//             account: "3347709242",
//             manager: "",
//           },
//         },
//         {
//           country: "IN",
//           network: "ads",
//           access: {
//             account: "6198373045",
//             manager: "",
//           },
//         },
//         {
//           country: "IN",
//           network: "dv",
//           access: {
//             account: "1048424336",
//           },
//         },
//         {
//           country: "IN",
//           network: "asa",
//           access: {
//             account: "3856110",
//           },
//         },
//       ],
//       collections: [
//         {
//           network: "ads",
//           reports: ["ads_summary"],
//         },
//         {
//           network: "dv",
//           reports: ["dv_summary"],
//         },
//         {
//           network: "ga",
//           reports: ["ga_summary"],
//         },
//         {
//           network: "fb",
//           reports: ["fb_summary"],
//         },
//         {
//           network: "asa",
//           reports: ["asa_summary"],
//         },
//         {
//           network: "ln",
//           reports: ["ln_summary"],
//         },
//       ],
//       process: {
//         extract: true,
//         consolidate: true,
//         finalize: true,
//         transport: true,
//       },
//     },
//     {
//       name: "Daily HK Ad Summary",
//       code: "HK.D.ADSUMMARY",
//       schedule: "0 1 * * *",
//       timezone: "Asia/Hong_Kong",
//       accounts: [
//         {
//           country: "HK",
//           network: "asa",
//           access: {
//             account: "3715650",
//             config_file: "config/asa_config_3715650.json",
//           },
//         },
//         {
//           country: "HK",
//           network: "asa",
//           access: {
//             account: "3715750",
//             config_file: "config/asa_config_3715750.json",
//           },
//         },
//         {
//           country: "HK",
//           network: "fb",
//           label: "DBS Loans",
//           access: {
//             account: "255731443362552",
//           },
//         },
//         {
//           country: "HK",
//           network: "fb",
//           label: "DBS Cards",
//           access: {
//             account: "465277394982218",
//           },
//         },
//         {
//           country: "HK",
//           network: "fb",
//           label: "DBS Banking",
//           access: {
//             account: "1014632719085209",
//           },
//         },
//         {
//           country: "HK",
//           network: "fb",
//           label: "DBS TPC",
//           access: {
//             account: "668382467497346",
//           },
//         },
//         {
//           country: "HK",
//           network: "fb",
//           label: "DBS",
//           access: {
//             account: "355608611229642",
//           },
//         },
//         {
//           country: "HK",
//           network: "ads",
//           access: {
//             account: "7162653353",
//             manager: "4472099872",
//           },
//         },
//         {
//           country: "HK",
//           network: "ads",
//           access: {
//             account: "6080804110",
//             manager: "4472099872",
//           },
//         },
//         {
//           country: "HK",
//           network: "ads",
//           access: {
//             account: "7630887205",
//             manager: "4472099872",
//           },
//         },
//         {
//           country: "HK",
//           network: "ads",
//           access: {
//             account: "5604348529",
//             manager: "4472099872",
//           },
//         },
//         {
//           country: "HK",
//           network: "dv",
//           access: {
//             account: "867322247",
//           },
//         },
//         {
//           country: "HK",
//           network: "bing",
//           label: "DBS Loan",
//           access: {
//             account: "15034284",
//             manager: "147009243",
//           },
//         },
//         {
//           country: "HK",
//           network: "bing",
//           label: "DBS - BlackCard",
//           access: {
//             account: "15034284",
//             manager: "3028738",
//           },
//         },
//       ],
//       collections: [
//         {
//           network: "ads",
//           reports: ["ads_summary"],
//         },
//         {
//           network: "dv",
//           reports: ["dv_summary"],
//         },
//         {
//           network: "ga",
//           reports: ["ga_summary"],
//         },
//         {
//           network: "fb",
//           reports: ["fb_summary"],
//         },
//         {
//           network: "asa",
//           reports: ["asa_summary"],
//         },
//         {
//           network: "ln",
//           reports: ["ln_summary"],
//         },
//         {
//           network: "bing",
//           reports: ["bing_summary"],
//         },
//         {
//           network: "email",
//           reports: [],
//         },
//       ],
//       process: {
//         extract: true,
//         consolidate: true,
//         finalize: false,
//         transport: false,
//       },
//       data_stability: {
//         period_look_back: 31,
//       },
//     },
//   ],
//   process: {
//     max_line_per_file: 0,
//   },
// };

export const general_config_uischema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Group",
      label: "Pipeline",
      elements: [
        {
          type: "Control",
          label: "Title",
          scope: "#/properties/pipelines/items/properties/name",
        },
        {
          type: "Control",
          label: "Code",
          scope: "#/properties/pipelines/items/properties/code",
        },
      ],
    },
    {
      type: "Group",
      label: "Frequency",
      elements: [
        {
          type: "Control",
          label: "Schedule (temp)",
          scope: "#/properties/pipelines/items/properties/schedule",
        },
        {
          type: "Control",
          label: "Timezone",
          scope: "#/properties/pipelines/items/properties/timezone",
        },
      ],
    },
  ],
};

export const account_schema = {
  $schema: "http://json-schema.org/draft-07/schema#",
  $id: "http://schema.kasatria.com/account.schema.json",
  title: "Account schema for pipeline",
  description: "The account schema for a pipeline.",
  type: "object",
  properties: {
    account: {
      type: "object",
      properties: {
        id: {
          type: "string",
        },
        country: {
          oneOf: [
            { title: "Afghanistan", const: "AF" },
            { title: "Åland Islands", const: "AX" },
            { title: "Albania", const: "AL" },
            { title: "Algeria", const: "DZ" },
            { title: "American Samoa", const: "AS" },
            { title: "AndorrA", const: "AD" },
            { title: "Angola", const: "AO" },
            { title: "Anguilla", const: "AI" },
            { title: "Antarctica", const: "AQ" },
            { title: "Antigua and Barbuda", const: "AG" },
            { title: "Argentina", const: "AR" },
            { title: "Armenia", const: "AM" },
            { title: "Aruba", const: "AW" },
            { title: "Australia", const: "AU" },
            { title: "Austria", const: "AT" },
            { title: "Azerbaijan", const: "AZ" },
            { title: "Bahamas", const: "BS" },
            { title: "Bahrain", const: "BH" },
            { title: "Bangladesh", const: "BD" },
            { title: "Barbados", const: "BB" },
            { title: "Belarus", const: "BY" },
            { title: "Belgium", const: "BE" },
            { title: "Belize", const: "BZ" },
            { title: "Benin", const: "BJ" },
            { title: "Bermuda", const: "BM" },
            { title: "Bhutan", const: "BT" },
            { title: "Bolivia", const: "BO" },
            { title: "Bosnia and Herzegovina", const: "BA" },
            { title: "Botswana", const: "BW" },
            { title: "Bouvet Island", const: "BV" },
            { title: "Brazil", const: "BR" },
            { title: "British Indian Ocean Territory", const: "IO" },
            { title: "Brunei Darussalam", const: "BN" },
            { title: "Bulgaria", const: "BG" },
            { title: "Burkina Faso", const: "BF" },
            { title: "Burundi", const: "BI" },
            { title: "Cambodia", const: "KH" },
            { title: "Cameroon", const: "CM" },
            { title: "Canada", const: "CA" },
            { title: "Cape Verde", const: "CV" },
            { title: "Cayman Islands", const: "KY" },
            { title: "Central African Republic", const: "CF" },
            { title: "Chad", const: "TD" },
            { title: "Chile", const: "CL" },
            { title: "China", const: "CN" },
            { title: "Christmas Island", const: "CX" },
            { title: "Cocos (Keeling) Islands", const: "CC" },
            { title: "Colombia", const: "CO" },
            { title: "Comoros", const: "KM" },
            { title: "Congo", const: "CG" },
            { title: "Congo, The Democratic Republic of the", const: "CD" },
            { title: "Cook Islands", const: "CK" },
            { title: "Costa Rica", const: "CR" },
            { title: 'Cote D"Ivoire', const: "CI" },
            { title: "Croatia", const: "HR" },
            { title: "Cuba", const: "CU" },
            { title: "Cyprus", const: "CY" },
            { title: "Czech Republic", const: "CZ" },
            { title: "Denmark", const: "DK" },
            { title: "Djibouti", const: "DJ" },
            { title: "Dominica", const: "DM" },
            { title: "Dominican Republic", const: "DO" },
            { title: "Ecuador", const: "EC" },
            { title: "Egypt", const: "EG" },
            { title: "El Salvador", const: "SV" },
            { title: "Equatorial Guinea", const: "GQ" },
            { title: "Eritrea", const: "ER" },
            { title: "Estonia", const: "EE" },
            { title: "Ethiopia", const: "ET" },
            { title: "Falkland Islands (Malvinas)", const: "FK" },
            { title: "Faroe Islands", const: "FO" },
            { title: "Fiji", const: "FJ" },
            { title: "Finland", const: "FI" },
            { title: "France", const: "FR" },
            { title: "French Guiana", const: "GF" },
            { title: "French Polynesia", const: "PF" },
            { title: "French Southern Territories", const: "TF" },
            { title: "Gabon", const: "GA" },
            { title: "Gambia", const: "GM" },
            { title: "Georgia", const: "GE" },
            { title: "Germany", const: "DE" },
            { title: "Ghana", const: "GH" },
            { title: "Gibraltar", const: "GI" },
            { title: "Greece", const: "GR" },
            { title: "Greenland", const: "GL" },
            { title: "Grenada", const: "GD" },
            { title: "Guadeloupe", const: "GP" },
            { title: "Guam", const: "GU" },
            { title: "Guatemala", const: "GT" },
            { title: "Guernsey", const: "GG" },
            { title: "Guinea", const: "GN" },
            { title: "Guinea-Bissau", const: "GW" },
            { title: "Guyana", const: "GY" },
            { title: "Haiti", const: "HT" },
            { title: "Heard Island and Mcdonald Islands", const: "HM" },
            { title: "Holy See (Vatican City State)", const: "VA" },
            { title: "Honduras", const: "HN" },
            { title: "Hong Kong", const: "HK" },
            { title: "Hungary", const: "HU" },
            { title: "Iceland", const: "IS" },
            { title: "India", const: "IN" },
            { title: "Indonesia", const: "ID" },
            { title: "Iran, Islamic Republic Of", const: "IR" },
            { title: "Iraq", const: "IQ" },
            { title: "Ireland", const: "IE" },
            { title: "Isle of Man", const: "IM" },
            { title: "Israel", const: "IL" },
            { title: "Italy", const: "IT" },
            { title: "Jamaica", const: "JM" },
            { title: "Japan", const: "JP" },
            { title: "Jersey", const: "JE" },
            { title: "Jordan", const: "JO" },
            { title: "Kazakhstan", const: "KZ" },
            { title: "Kenya", const: "KE" },
            { title: "Kiribati", const: "KI" },
            {
              title: 'Korea, Democratic People"S Republic of',
              const: "KP",
            },
            { title: "Korea, Republic of", const: "KR" },
            { title: "Kuwait", const: "KW" },
            { title: "Kyrgyzstan", const: "KG" },
            { title: 'Lao People"S Democratic Republic', const: "LA" },
            { title: "Latvia", const: "LV" },
            { title: "Lebanon", const: "LB" },
            { title: "Lesotho", const: "LS" },
            { title: "Liberia", const: "LR" },
            { title: "Libyan Arab Jamahiriya", const: "LY" },
            { title: "Liechtenstein", const: "LI" },
            { title: "Lithuania", const: "LT" },
            { title: "Luxembourg", const: "LU" },
            { title: "Macao", const: "MO" },
            {
              title: "Macedonia, The Former Yugoslav Republic of",
              const: "MK",
            },
            { title: "Madagascar", const: "MG" },
            { title: "Malawi", const: "MW" },
            { title: "Malaysia", const: "MY" },
            { title: "Maldives", const: "MV" },
            { title: "Mali", const: "ML" },
            { title: "Malta", const: "MT" },
            { title: "Marshall Islands", const: "MH" },
            { title: "Martinique", const: "MQ" },
            { title: "Mauritania", const: "MR" },
            { title: "Mauritius", const: "MU" },
            { title: "Mayotte", const: "YT" },
            { title: "Mexico", const: "MX" },
            { title: "Micronesia, Federated States of", const: "FM" },
            { title: "Moldova, Republic of", const: "MD" },
            { title: "Monaco", const: "MC" },
            { title: "Mongolia", const: "MN" },
            { title: "Montserrat", const: "MS" },
            { title: "Morocco", const: "MA" },
            { title: "Mozambique", const: "MZ" },
            { title: "Myanmar", const: "MM" },
            { title: "Namibia", const: "NA" },
            { title: "Nauru", const: "NR" },
            { title: "Nepal", const: "NP" },
            { title: "Netherlands", const: "NL" },
            { title: "Netherlands Antilles", const: "AN" },
            { title: "New Caledonia", const: "NC" },
            { title: "New Zealand", const: "NZ" },
            { title: "Nicaragua", const: "NI" },
            { title: "Niger", const: "NE" },
            { title: "Nigeria", const: "NG" },
            { title: "Niue", const: "NU" },
            { title: "Norfolk Island", const: "NF" },
            { title: "Northern Mariana Islands", const: "MP" },
            { title: "Norway", const: "NO" },
            { title: "Oman", const: "OM" },
            { title: "Pakistan", const: "PK" },
            { title: "Palau", const: "PW" },
            { title: "Palestinian Territory, Occupied", const: "PS" },
            { title: "Panama", const: "PA" },
            { title: "Papua New Guinea", const: "PG" },
            { title: "Paraguay", const: "PY" },
            { title: "Peru", const: "PE" },
            { title: "Philippines", const: "PH" },
            { title: "Pitcairn", const: "PN" },
            { title: "Poland", const: "PL" },
            { title: "Portugal", const: "PT" },
            { title: "Puerto Rico", const: "PR" },
            { title: "Qatar", const: "QA" },
            { title: "Reunion", const: "RE" },
            { title: "Romania", const: "RO" },
            { title: "Russian Federation", const: "RU" },
            { title: "RWANDA", const: "RW" },
            { title: "Saint Helena", const: "SH" },
            { title: "Saint Kitts and Nevis", const: "KN" },
            { title: "Saint Lucia", const: "LC" },
            { title: "Saint Pierre and Miquelon", const: "PM" },
            { title: "Saint Vincent and the Grenadines", const: "VC" },
            { title: "Samoa", const: "WS" },
            { title: "San Marino", const: "SM" },
            { title: "Sao Tome and Principe", const: "ST" },
            { title: "Saudi Arabia", const: "SA" },
            { title: "Senegal", const: "SN" },
            { title: "Serbia and Montenegro", const: "CS" },
            { title: "Seychelles", const: "SC" },
            { title: "Sierra Leone", const: "SL" },
            { title: "Singapore", const: "SG" },
            { title: "Slovakia", const: "SK" },
            { title: "Slovenia", const: "SI" },
            { title: "Solomon Islands", const: "SB" },
            { title: "Somalia", const: "SO" },
            { title: "South Africa", const: "ZA" },
            {
              title: "South Georgia and the South Sandwich Islands",
              const: "GS",
            },
            { title: "Spain", const: "ES" },
            { title: "Sri Lanka", const: "LK" },
            { title: "Sudan", const: "SD" },
            { title: "Suriname", const: "SR" },
            { title: "Svalbard and Jan Mayen", const: "SJ" },
            { title: "Swaziland", const: "SZ" },
            { title: "Sweden", const: "SE" },
            { title: "Switzerland", const: "CH" },
            { title: "Syrian Arab Republic", const: "SY" },
            { title: "Taiwan", const: "TW" },
            { title: "Tajikistan", const: "TJ" },
            { title: "Tanzania, United Republic of", const: "TZ" },
            { title: "Thailand", const: "TH" },
            { title: "Timor-Leste", const: "TL" },
            { title: "Togo", const: "TG" },
            { title: "Tokelau", const: "TK" },
            { title: "Tonga", const: "TO" },
            { title: "Trinidad and Tobago", const: "TT" },
            { title: "Tunisia", const: "TN" },
            { title: "Turkey", const: "TR" },
            { title: "Turkmenistan", const: "TM" },
            { title: "Turks and Caicos Islands", const: "TC" },
            { title: "Tuvalu", const: "TV" },
            { title: "Uganda", const: "UG" },
            { title: "Ukraine", const: "UA" },
            { title: "United Arab Emirates", const: "AE" },
            { title: "United Kingdom", const: "GB" },
            { title: "United States", const: "US" },
            { title: "United States Minor Outlying Islands", const: "UM" },
            { title: "Uruguay", const: "UY" },
            { title: "Uzbekistan", const: "UZ" },
            { title: "Vanuatu", const: "VU" },
            { title: "Venezuela", const: "VE" },
            { title: "Viet Nam", const: "VN" },
            { title: "Virgin Islands, British", const: "VG" },
            { title: "Virgin Islands, U.S.", const: "VI" },
            { title: "Wallis and Futuna", const: "WF" },
            { title: "Western Sahara", const: "EH" },
            { title: "Yemen", const: "YE" },
            { title: "Zambia", const: "ZM" },
            { title: "Zimbabwe", const: "ZW" },
          ],
        },
        network: {
          oneOf: [
            { const: "ads", title: "Google Ads" },
            { const: "dv", title: "Google DV360" },
            { const: "ga", title: "Google Analytics" },
            { const: "ga4", title: "Google Analytics 4" },
            { const: "fb", title: "Facebook" },
            { const: "asa", title: "Apple Search Ads" },
            { const: "ln", title: "LinkedIns" },
            { const: "bing", title: "Bing" },
            { const: "email", title: "Email" },
          ],
        },
        label: {
          type: "string",
        },
        name: {
          type: "string",
        },
        access: {
          type: "object",
          properties: {
            property: {
              type: "string",
            },
            view: {
              type: "string",
            },
            account: {
              type: "string",
            },
            manager: {
              type: "string",
            },
          },
        },
      },
      required: ["country", "network"],
      if: {
        properties: {
          network: { const: "ga" },
        },
      },
      then: {
        properties: {
          access: {
            type: "object",
            properties: {
              property: {
                type: "string",
              },
              view: {
                type: "string",
              },
            },
            required: ["property", "view"],
          },
        },
      },
      else: {
        if: {
          properties: {
            network: { const: "ga4" },
          },
        },
        then: {
          properties: {
            access: {
              type: "object",
              properties: {
                property: {
                  type: "string",
                },
              },
              required: ["property"],
            },
          },
        },
        else: {
          properties: {
            access: {
              type: "object",
              properties: {
                account: {
                  type: "string",
                },
                manager: {
                  type: "string",
                },
              },
              required: ["account"],
            },
          },
        },
      },
      // else: {
      //   if: {
      //     properties: {
      //       network: { const: "ga4" },
      //     },
      //   },
      //   then: {
      //     properties: {
      //       access: {
      //         type: "object",
      //         properties: {
      //           property: {
      //             type: "string",
      //           },
      //         },
      //         required: ["property"],
      //       },
      //     },
      //   },
      //   else: {
      //     properties: {
      //       access: {
      //         type: "object",
      //         properties: {
      //           account: {
      //             type: "string",
      //           },
      //           manager: {
      //             type: "string",
      //           },
      //         },
      //         required: ["account", "manager"],
      //       },
      //     },
      //   },
      // },
    },
  },
};

export const theme = createTheme({
  palette: {
    primary: {
      main: "#2f77ad",
    },
    secondary: {
      main: "#b53535",
    },
  },
  spacing: 4,
});
