import React from "react";
import ReactDOM from "react-dom/client";
import "./static/css/index.css";
import Pipeline from "./pages/Pipeline";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Switch } from "@mui/material";
import AccountsCreateOrUpdate from "./pages/AccountsCreateOrUpdate";
import AccountsList from "./pages/AccountsList";
import AccountsMultiManage from "./pages/AccountsMultiManage";
import PipelineCreateOrUpdate from "./pages/PipelineCreateOrUpdate";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Pipeline />,
  },
  {
    path: "/accounts/:id/:update",
    element: <AccountsCreateOrUpdate />,
  },
  {
    path: "/accounts/:id",
    element: <AccountsCreateOrUpdate />,
  },
  {
    path: "/accounts/new",
    element: <AccountsCreateOrUpdate />,
  },
  {
    path: "/accounts",
    element: <AccountsList />,
  },
  {
    path: "/accounts/multi",
    element: <AccountsMultiManage />,
  },
  {
    path: "/p/new",
    element: <PipelineCreateOrUpdate />,
  },
  {
    path: "/p/:pipeline_code",
    element: <PipelineCreateOrUpdate />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <RouterProvider router={router} />
  </LocalizationProvider>
);
